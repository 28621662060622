<template>
  <form
    class="form"
    :style="`background-color: ${colors.background};color: ${colors.font};`"
    @submit.prevent="register"
  >
    <div class="form__container">
      <div
        v-if="source !== 'subscribeForm'"
        class="logo"
      >
        <Fingerprint />
      </div>
      <div class="fieldset">
        <div class="field">
          <v-text-field
            v-model="firstName"
            type="text"
            placeholder="First name"
            title="Please fill out this field"
            required
          />
        </div>
        <div class="field">
          <v-text-field
            v-model="lastName"
            type="text"
            placeholder="Last name"
            title="Please fill out this field"
            required
          />
        </div>
        <div class="field">
          <v-text-field
            v-model="username"
            type="text"
            placeholder="Username"
            title="Please fill out this field"
            required
          />
        </div>
        <div class="field">
          <v-text-field
            v-model="email"
            type="text"
            placeholder="Email"
            title="Please fill out this field"
            required
          />
        </div>
        <div class="field">
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            placeholder="Password"
            title="Please fill out this field"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min, rules.strength]"
            required
            @click:append="showPassword = !showPassword"
          />
        </div>
        <div class="field">
          <v-text-field
            v-model="confirmPassword"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            placeholder="Confirm password"
            title="Please fill out this field"
            :rules="[rules.required, rules.confirm]"
            required
            @click:append="showPassword = !showPassword"
          />
        </div>
      </div>
      <v-btn
        class="btn"
        type="submit"
        :loading="registerBusy"
      >
        Sign up
      </v-btn>
      <div class="text">
        By creating an account, you agree and accept our <a
          :style="`text-decoration: underline; cursor: pointer; color: ${colors.font} !important;`"
          href="#"
        >Terms</a> and <a
          :style="`text-decoration: underline; cursor: pointer; color: ${colors.font} !important;`"
          href="#"
        >Privacy Policy</a>.
      </div>
    </div>
    <div
      v-if="source !== 'subscribeForm'"
      class="form__footer"
    >
      Already have an account ?
      <RouterLink to="login">
        Log in
      </RouterLink>
      .
    </div>
  </form>
</template>

<script>

import Fingerprint from "../../assets/Fingerprint.svg";
import DataMaLogicAbstract from "../Structure/Mixins/DataMaLogicAbstract.vue";
import AuthAbstract from "./Mixins/AuthAbstract.vue";

export default {
  name: 'RegisterFormForm',
  components: {Fingerprint},
  mixins: [
    DataMaLogicAbstract,
    AuthAbstract
  ],
  props: {
    source: {
      type: String,
      default: 'default'
    },
    propColor: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      defaultColors: {
        background: '#FFFFFF',
        font: '#000000',
      },
      showPassword: false,
      password: '',
      confirmPassword: '',
      email: '',
      username: '',
      firstName: '',
      lastName: '',
      registerBusy: false,
    };
  },
  computed: {
    colors() {
      return {
        font: this.propColor?.font || this.defaultColors.font,
        background: this.propColor?.background || this.defaultColors.background
      }
    }
  },
  methods: {
    register() {
      const payload = {
        first_name: this.firstName,
        last_name: this.lastName,
        username: this.username,
        email: this.email,
        password: this.password
      }
      this.registerBusy = true
      if (this.password === this.confirmPassword){
        this.$store.dispatch("security/register", {data: payload})
            .then(response => {
              // Handle successful authentication, store tokens in cookies
              if (this.developer) console.log('Registration successful:', response.data)
              this.storeAuthCookies(response.data.auth)
              this.registerBusy = false
              this.authenticate().then(() => {
                // this.$router.push({name: 'core:main'})
              })
            })
            .catch(error => {
              this.registerBusy = false
              // Handle authentication failure
              if (this.developer) console.error('Authentication failed:', error.response.data)
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme/components/forms.scss";

a {
  color: white !important;
  -webkit-transition: opacity .2s !important;
  -o-transition: opacity .2s !important;
  transition: opacity .2s !important;
  text-decoration: underline !important;
}

a:hover {
  opacity: .7;
}

.form {
  width: 100%;
  //max-width: 432px;
  //margin: 0 auto;
  //background: #005450;
  //-webkit-box-shadow: 0 16px 22px -10px rgba(0, 0, 0, 0.1), 0 34px 55px 4px rgba(0, 0, 0, 0.05), 0 13px 66px 12px rgba(0, 0, 0, 0.07);
  //box-shadow: 0 16px 22px -10px rgba(0, 0, 0, 0.1), 0 34px 55px 4px rgba(0, 0, 0, 0.05), 0 13px 66px 12px rgba(0, 0, 0, 0.07);
  //border-radius: 12px;
  height: fit-content;
  //border: 0;
  //z-index: 1;
  //scale: 0.85;
}

.logo {
  margin-bottom: 32px;
}

.fieldset {
  margin-bottom: 28px;
}

.btn {
  min-width: 234px !important;
  height: 50px !important;
  background: var(--color-secondary) !important;
  color: white !important;
  border-radius: 25px;
  font-size: 20px !important;
  font-weight: 700;
  letter-spacing: -0.3px;
  -webkit-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
}


.btn:hover {
  opacity: .7;
}

.text {
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.375;
}

@media only screen and (max-width: 767px) {
  .form__container {
    padding: 52px 24px 38px;
  }
}
</style>