<template>
  <v-tabs
    v-model="current_tab"
    right
    height="30"
    class="chart-action"
  >
    <template v-for="(action, index) in actions">
      <v-tab
        v-if="action.disabled===false"
        :key="index"
        centered
      >
        <div>
          <div v-if="action.icon.type ==='text'">
            <p v-html="action.icon.data">
              {{ action.icon.data }}
            </p>
          </div>
          <v-icon
            v-if="action.icon.type ==='mdi' && action.hover && action.hover.text"
            v-b-tooltip.hover.top="action.hover.text"
          >
            {{ action.icon.data }}
          </v-icon>
          <v-icon v-if="action.icon.type ==='mdi' && !action.hover || !action.hover.text">
            {{ action.icon.data }}
          </v-icon>
        </div>
      </v-tab>
    </template>
  </v-tabs>
</template>
<script>
export default {
  name: 'ChartAction',
  props: {
    actions: {
      type: Array,
      default() {
        return []
      }
    },
    tab: {
      type: Number,
      default: 0
    },
  },
  computed: {
    current_tab: {
      get() {
        return this.tab
      },
      set(e) {
        return this.$emit("changeTab", e)
      }
    }
  },
  mounted() {
  },
  methods: {}

}
</script>

<style lang="scss">
.chart-action {
  & .v-tab {
    text-transform: none !important;

    & p {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  & .v-tabs-slider-wrapper {
    top: 0 !important;;
  }
}

</style>