<template>
  <v-badge
    :color="color"
    :content="message"
    inline
  />
</template>

<script>
export default {
  name: "BetaBadge",
  props: {
    message: {
      type: String,
      default: 'beta'
    },
    color: {
      type: String,
      default: 'info'
    }
  }
}
</script>
<style lang="scss" scoped>
.infoAside{
  line-height: 24px;
  word-break: break-word;
}
</style>
