var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('small',[_c('v-card',{attrs:{"tile":"","elevation":"0","loading":_vm.userLoading}},[(_vm.shared_credential && !_vm.loading)?_c('v-select',{staticClass:"credential-select",attrs:{"items":_vm.items,"loading":_vm.loading,"menu-props":"offsetY","item-text":"text","height":"36","background-color":"white","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[(item.organization)?_c('v-list-item-avatar',[_c('OrgAvatar',{attrs:{"editable":false,"size":24,"org":item.organization}})],1):(item.img_url)?_c('v-list-item-avatar',[(item.img_url !== '')?_c('v-avatar',{staticClass:"m-0",attrs:{"size":"24"}},[(_vm.isValidHttpUrl(item.img_url))?_c('img',{attrs:{"src":item.img_url,"alt":item.text}}):_vm._e()]):_vm._e()],1):_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"m-0",attrs:{"size":"24"}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-account-circle ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_vm._v(_vm._s(item.text)+" "),(!item.organization)?_c('v-btn',{staticClass:"dataredbg d-flex justify-center align-center ml-4",attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',[_vm._v("mdi-power")])],1):_vm._e()],1)],1)],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[(item.organization)?_c('v-list-item-avatar',[_c('OrgAvatar',{attrs:{"editable":false,"size":40,"org":item.organization}})],1):(item.img_url)?_c('v-list-item-avatar',[(item.img_url !== '')?_c('v-avatar',{staticClass:"m-0",attrs:{"size":"40"}},[(_vm.isValidHttpUrl(item.img_url))?_c('img',{attrs:{"src":item.img_url,"alt":item.text}}):_vm._e()]):_vm._e()],1):_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"m-0",attrs:{"size":"40"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account-circle ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)],1)]}}],null,false,1677910108),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}):(!_vm.loading && !_vm.shared_credential)?_c('v-card-title',[_c('small',[(_vm.img_url != null && _vm.img_url !== '')?_c('v-avatar',{staticClass:"mr-3",attrs:{"left":"","size":"30"}},[(_vm.isValidHttpUrl(_vm.img_url))?_c('img',{attrs:{"src":_vm.img_url,"alt":_vm.email}}):_c('v-icon',[_vm._v(_vm._s(_vm.img_url))])],1):(_vm.img_url == null || _vm.img_url === '')?_c('v-icon',{attrs:{"left":"","x-large":""}},[_vm._v("mdi-account-circle ")]):_vm._e(),_c('small',[_vm._v(" "+_vm._s(_vm.name))])],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","small":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-btn',{staticClass:"dataredbg",attrs:{"dark":""},on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',[_vm._v("mdi-power")]),_vm._v(" Logout ")],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }