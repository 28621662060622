<template>
  <div>
    <v-btn
      color="blue"
      class="text-yellow shadow-none transparent"
      style="color:white;"
      @click="openModal()"
    >
      <v-icon left>
        mdi-account-cog
      </v-icon>
      Role
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card
        class="datama-dialog"
      >
        <v-card-title>
          <span>
            Edit {{ user.name }} Roles
          </span>
        </v-card-title>
        <v-card-text>
          <RolesSelect
            v-if="dialog===true"
            :user="user"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrgAbstract from "../../Org/Mixins/OrgAbstract.vue";
import AdminAbstract from "../Mixins/AdminAbstract.vue";
import RolesSelect from "@/components/Home/Admin/Selects/RolesSelect.vue";

export default {
  name: 'ManageUserRolesModal',
  components: {RolesSelect},
  mixins: [
    OrgAbstract, AdminAbstract
  ],
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {},
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
  },
};

</script>