<template>
  <div>
    <v-btn
      v-if="licenseState === 'no_trial'"
      color="red"
      class="shadow-none transparent"
      style="color:white;"
      @click="openModal()"
    >
      <v-icon left>
        mdi-close
      </v-icon>
      No trial
    </v-btn>
    <v-btn
      v-else-if="licenseState === 'full_app'"
      color="green"
      class="shadow-none transparent"
      style="color:white;"
      @click="openModal()"
    >
      <v-icon left>
        mdi-clock-check-outline
      </v-icon>
      License full app
    </v-btn>
    <v-btn
      v-else-if="licenseState === 'trial'"
      color="orange"
      class="shadow-none transparent"
      style="color:white;"
      @click="openModal()"
    >
      <v-icon left>
        mdi-progress-clock
      </v-icon>
      In trial until XX/XX/XXXX
    </v-btn>
    <v-btn
      v-else-if="licenseState === 'full_app_ended'"
      color="red"
      class="shadow-none transparent"
      style="color:white;"
      @click="openModal()"
    >
      <v-icon left>
        mdi-clock-remove-outline
      </v-icon>
      License ended
    </v-btn>
    <v-btn
      v-else-if="licenseState === 'trial_ended'"
      color="red"
      class="shadow-none transparent"
      style="color:white;"
      @click="openModal()"
    >
      <v-icon left>
        mdi-clock-remove-outline
      </v-icon>
      Trial ended
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        {{ user }}
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import AdminAbstract from "@/components/Home/Admin/Mixins/AdminAbstract.vue";
import {now} from "moment/moment";

export default {
  name: 'ManageTrialModal',
  components: {},
  mixins: [
    OrgAbstract, AdminAbstract
  ],
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
    };
  },
  computed: {
    licenseState: {
      get() {
        if (this.user.subscription?.trial) {
          if (this.user.subscription.trial) {
            if (this.user.subscription.endDate > new Date(now())) {
              return "trial"
            } else {
              return "trial_ended"
            }
          } else {
            if (this.user.subscription?.endDate > new Date(now())) {
              return "full_app"
            } else {
              return "full_app_ended"
            }
          }
        } else {
          return "no_trial"
        }
      },
      set(value) {
        /*this.$store.commit('admin/setLicenseState', value)*/
      }
    },
  }
  ,
  watch: {}
  ,
  methods: {
    openModal() {
      this.dialog = true;
    }
    ,
    impersonateUser() {
      this.loading = true
      this.$store.dispatch("admin/impersonateUser", {
        data: {
          uid: this.selectedUser?.uid
        },
      }).then((response) => {
        this.storeImpersonateUser(response.data.auth)
        this.loading = false
        this.dialog = false
      }).catch((err) => {
        this.loading = false
        this.$toast.error(err.toString())
        console.error(err)
      })
    }
    ,
    closeModal() {
      this.dialog = false;
    }
    ,
  }
  ,
}
;

</script>