import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css"; // Add this line
import colors from "@/theme/config/_colors.scss";

Vue.use(Vuetify);
const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.primary,
        secondary: colors.secondary,
        // accent: colors.secondary,
        error: colors.error,
      },
    },
  },
  options: {
    customProperties: true
  },
  icons: {
    iconfont: "mdi"
  }
};

export default new Vuetify(opts);