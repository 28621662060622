<template>
  <div class="credentials-container">
    <div
      v-for="(credential) in formatedCredentialsWithSource"
      :key="credential.id"
      v-b-tooltip.hover.bottom="credential.uid == null ? 'Connect your accounts in prep.' : ''"
      class="credential-tile"
    >
      <div
        class="tile-header"
        :class="credential.uid != null ? '' : 'disabled'"
      >
        <div class="icon">
          <span
            class="block-icon"
            :class="credential.css_class"
            v-html="credential.css_icon"
          />
        </div>
        <h3 class="ml-5">
          {{ credential.name }}
        </h3>
      </div>
      <div
        v-if="credential.uid != null"
        class="tile-header"
      >
        Share :
        <v-switch
          v-model="credential.shared"
          class="p-0 m-0 ml-5 float-right"
          hide-details
          @change="onSwitchChange(credential)"
        />
      </div>

      <!--      <v-btn
              v-if="credential.uid == null"
              style="color: white !important"
              color="primary"
              :loading="sharedCredentialBusy"
              @click="deleteCredential(credential)"
            >
              <v-icon
                class="mr-1"
                small
              >
                mdi-open-in-new
              </v-icon>
              Connect
            </v-btn>-->
      <v-btn
        v-if="credential.uid == null"
        style="color: white !important"
        color="primary"
        class="disabled"
        :loading="sharedCredentialBusy"
      >
        <v-icon
          class="mr-1"
          small
        >
          mdi-open-in-new
        </v-icon>
        Not Connected
      </v-btn>
      <v-btn
        v-else
        color="error"
        :loading="sharedCredentialBusy"
        @click="deleteCredential(credential)"
      >
        Disconnect
      </v-btn>
    </div>
  </div>
</template>

<script>
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";

export default {
  name: 'CredentialCardsList',
  mixins: [
    DataMaLogicAbstract, OrgAbstract
  ],
  data() {
    return {
      sharedCredentialBusy: false
    };
  },
  computed: {},
  mounted() {
    this.refreshCredentials()
  },
  methods: {
    refreshCredentials() {
      this.getAllUserCredentials()
      this.getSharedCredentials()
    },
    onSwitchChange(credential) {
      if (credential.shared) {
        this.createSharedCredential(credential);
      } else {
        this.deleteSharedCredential(credential)
      }
    },
    createSharedCredential(credential) {
      if (credential.uid != null) {
        this.sharedCredentialBusy = true;
        const payload = {
          uid: credential.uid,
          oid: this.organization.oid,
          token_id: credential.token_id
        };
        this.$store.dispatch("organization/createSharedCredential", {data: payload})
            .then(response => {
              this.sharedCredentialBusy = false;
              this.registerToast(this.$toast.success('Credential shared successfully!', {timeout: 3000}));
            })
            .catch(error => {
              this.sharedCredentialBusy = false;
              this.registerToast(this.$toast.error('Failed to share credential. Please retry.', {timeout: 10000}));
              // Revert the switch back to off
              this.$nextTick(() => {
                credential.shared = false;
              });
            })
            .finally(() => {
              this.refreshCredentials()
            })
      }
    },
    deleteSharedCredential(credential) {
      if (credential.uid != null && credential.shared_credential != null) {
        this.sharedCredentialBusy = true;
        this.$store.dispatch("organization/deleteSharedCredential", {params: {"shared_credential_id": credential.shared_credential.id}})
            .then(response => {
              this.sharedCredentialBusy = false;
              this.registerToast(this.$toast.success('Credential unshared successfully!', {timeout: 3000}));
            })
            .catch(error => {
              this.sharedCredentialBusy = false;
              this.registerToast(this.$toast.error('Failed to unshare credential. Please retry.', {timeout: 10000}));
              // Revert the switch back to off
              this.$nextTick(() => {
                credential.shared = true;
              });
            })
            .finally(() => {
              this.refreshCredentials()
            })
      }
    },
    deleteCredential(credential) {
      this.$store.dispatch("prep/serviceLogout", {
        params:
            {
              service: credential.service
            }
      }).then(() => {
        return this.refreshCredentials()
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.credentials-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.credential-tile {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 0;
  position: relative;
}

.disabled {
  opacity: 0.5 !important;
}

.credential-tile button {
  position: relative;
  z-index: 2; /* Ensure the button is above the overlay */
  pointer-events: auto; /* Re-enable interactions with the button */
  opacity: 1; /* Ensure the button is fully visible */
}

.tile-header {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 8px;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  border: var(--border);
  border-radius: 25%;
}

.tile-header h3 {
  margin: 10px 0;
  font-size: 18px;
}

.tile-header p {
  margin: 2px 0;
  color: #666;
}


</style>


<style lang="scss">
.credential-tile {
  & .block-icon {
    display: flex;
    font-size: 40px;
    justify-content: center;

    //Snowflake
    & > span:before {
      margin-left: 0;
      font-size: 22px;
    }

    & :before {
      line-height: 50px;
      align-self: center;
    }

    & .path1 {
      display: inline-block;
      margin-left: 0;
      width: 1em;
    }
  }
}
</style>
