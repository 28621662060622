<template>
  <div
    class="slide-configuration"
  >
    <v-row>
      <v-col
        cols="3"
        class="pl-0 pr-0 border-right"
      >
        <v-list
          dense
        >
          <v-list-item
            v-b-tooltip.hover.top="'Edit general settings'"
            link
            :class="(panel === 0 ? 'active' : '') + ' pr-1'"
            @click.stop="panel = 0"
          >
            <v-list-item-icon class="mr-2">
              <v-icon>
                mdi-cogs
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                General
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        cols="9"
        class="pr-0"
      >
        <div class="slide-table-configuration mb-5">
          <v-row>
            <v-col cols="12">
              <h5 class="m-0 mb-5">
                <v-icon class="mr-2">
                  mdi-cogs
                </v-icon>
                General settings
              </h5>
            </v-col>
            <v-col cols="12">
              <v-row
                class="d-flex flex-nowrap text-center mb-5"
                style="height: 40px; line-height: 40px; width: 100%; font-size: 14px; font-weight: 500 !important;"
              >
                <v-row
                  class="d-flex justify-center align-items-center p-0"
                >
                  <v-col cols="3" />
                  <v-col
                    cols="9"
                    class="text-left"
                  >
                    <strong>Column type</strong>
                  </v-col>
                </v-row>
                <v-col
                  cols="2"
                  class="separated"
                >
                  <strong>Arrows</strong>
                </v-col>
                <v-col
                  cols="2"
                >
                  <strong>Bars</strong>
                </v-col>
                <v-col
                  cols="2"
                >
                  <strong>Colors</strong>
                </v-col>
                <v-col
                  cols="1"
                >
                  <strong>Pin</strong>
                </v-col>
              </v-row>
              <draggable
                v-model="configuration.columns"
                @start="drag=true"
                @end="drag=false"
              >
                <v-row
                  v-for="(line, index) in configuration.columns"
                  :key="'slide-configuration-table-' + index"
                  :class="'d-flex flex-nowrap text-center ' + (line.active ? 'active' : 'disabled')"
                  style="height: 40px; line-height: 40px; width: 100%; font-size: 14px;"
                >
                  <v-col
                    cols="5"
                    class="text-left"
                    style="font-weight: 500;"
                    :style="!line.active ? 'opacity: 1 !important;' : ''"
                  >
                    <v-row>
                      <v-col
                        cols="2"
                        class="p-0 text-center"
                        style="cursor: grab !important;"
                      >
                        <v-icon
                          x-small
                          style="cursor: grab !important;"
                        >
                          fa-grip-vertical
                        </v-icon>
                      </v-col>
                      <v-col
                        :id="'bridge-configuration-column-line' + index"
                        cols="1"
                        class="d-flex justify-center align-items-center p-0"
                        :style="!line.active ? 'opacity: 1 !important;' : ''"
                      >
                        <b-tooltip
                          v-if="configuration.columns.filter(c => c.visible && c.active).length === 1 && (line.visible && line.active)"
                          placement="bottom"
                          boundary="scrollParent"
                          :boundary-padding="-30"
                          :target="'bridge-configuration-column-line' + index"
                          triggers="hover"
                        >
                          You need at least 1 pinned column
                        </b-tooltip>
                        <v-checkbox
                          v-model="line.active"
                          hide-details="auto"
                          class="m-0 p-0"
                          :disabled="configuration.columns.filter(c => c.visible && c.active).length < 2 && line.visible"
                          @change="v => !v ? line.visible = false : v"
                        />
                      </v-col>
                      <v-col
                        v-b-tooltip.hover.top="wording[line.text]"
                        cols="9"
                        :style="!line.active ? 'opacity: 0.5 !important;' : ''"
                      >
                        {{ line.text }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="2"
                    class="separated d-flex justify-center align-items-center"
                  >
                    <v-checkbox
                      v-model="line.arrows"
                      :disabled="!line.active"
                      hide-details="auto"
                      class="m-0 p-0"
                    />
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-center align-items-center"
                  >
                    <v-checkbox
                      v-model="line.bars"
                      :disabled="!line.active"
                      hide-details="auto"
                      class="m-0 p-0"
                    />
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-center align-items-center"
                  >
                    <v-checkbox
                      v-model="line.colors"
                      :disabled="!line.active"
                      hide-details="auto"
                      class="m-0 p-0"
                    />
                  </v-col>
                  <v-col
                    cols="1"
                    class="separated d-flex justify-center align-items-center p-0"
                  >
                    <v-checkbox
                      v-model="line.visible"
                      hide-details="auto"
                      class="m-0 p-0"
                      :disabled="!line.active"
                    />
                  </v-col>
                </v-row>
              </draggable>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import ConfigurationAbstract from "@/components/Core/Solutions/Structure/Slides/Mixins/Configuration/ConfigurationAbstract.vue";
import ConfigurableSlideAbstract from "@/components/Core/Solutions/Structure/Slides/Mixins/ConfigurableSlideAbstract.vue";

export default {
  name: 'CompareTableConfiguration',
  components: {
    draggable
  },
  mixins: [
    ConfigurationAbstract,
    ConfigurableSlideAbstract
  ],
  data: () => ({
    drag: false,
    panel: 0,
    wording: {
      'Value': 'Value of segment',
      'Impact': 'Impact on dimension',
      '% ch.': 'Change versus comparison',
      'TotalDriver_Scaled': 'Main KPI impact',
      'Ranking': 'Current segment position (ordered by interest)',
      'Ranking change': 'Segment evolution (ordered by interest)',
    }
  })
}
</script>
<style lang="scss">
.slide-table-configuration {
  & .active .col {
    opacity: 1;
    transition: .3s ease-in;
  }
  & .disabled .col {
    opacity: 0.5;
    transition: .2s ease-in;
  }
  & .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
  & .separated::after {
    left: 0;
  }
}
</style>