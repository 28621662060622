<template>
  <div
    class="container"
    v-html="msg"
  />
</template>

<script>
export default {
  props: {
    toastId: {
      type: [String, Number],
      required: true
    },
    msg: {
      type: String,
      default: ''
    }
  },
  methods: {
  }
};
</script>

<style>

</style>