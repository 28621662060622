<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="6"
      sm="12"
    >
      <div
        class="d-flex align-center justify-space-between"
      >
        <div class="field">
          <h6>Shared workbooks limit</h6>
          <v-text-field
            v-model="shared_workbooks_limit"
            class="ml-5"
            @keyup.enter="emitQuotas"
          />
        </div>
        <div class="field">
          <h6>Workbook rows limit</h6>
          <v-text-field
            v-model="workbook_rows_limit"
            class="ml-5"
            @keyup.enter="emitQuotas"
          />
        </div>
        <v-btn
          class="bg-primary shadow-none ml-2"
          :disabled="!isValueDiff"
          @click="emitQuotas"
        >
          Submit
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import OrgAbstract from "../../Org/Mixins/OrgAbstract.vue";
import AdminAbstract from "../Mixins/AdminAbstract.vue";

export default {
  name: 'QuotasInputs',
  components: {},
  mixins: [
    OrgAbstract, AdminAbstract
  ],
  props: {
    org: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      shared_workbooks_limit: null,
      workbook_rows_limit: null
    };
  },
  computed: {
    isValueDiff() {
      return !(this.workbook_rows_limit === this.org.workbook_rows_limit && this.shared_workbooks_limit === this.org.shared_workbooks_limit);
    },
  },
  mounted() {
    this.workbook_rows_limit = this.org.workbook_rows_limit;
    this.shared_workbooks_limit = this.org.shared_workbooks_limit;
  },
  methods: {
    emitQuotas() {
      this.$emit('update-quotas', this.shared_workbooks_limit, this.workbook_rows_limit)
    }
  },
};

</script>


<style scoped lang="scss">
.field {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
</style>