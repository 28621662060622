<template>
  <div class="slide-configuration">
    <v-row>
      <v-col
        cols="3"
        class="pl-0 pr-0 border-right"
      >
        <v-list
          dense
        >
          <v-list-item
            v-b-tooltip.hover.top="'Edit general settings'"
            link
            :class="(panel === 0 ? 'active' : '') + ' pr-1'"
            @click.stop="panel = 0"
          >
            <v-list-item-icon class="mr-2">
              <v-icon>
                mdi-cogs
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                General
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="comparison && configuration.breadcrumb.active"
            v-b-tooltip.hover.top="'Edit breadcrumb settings'"
            link
            :class="(panel === 1 ? 'active' : '') + ' pr-1'"
            @click.stop="panel = 1"
          >
            <v-list-item-icon class="mr-2">
              <v-icon>
                mdi-table
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Breadcrumbs
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        cols="9"
        class="pr-0"
      >
        <v-card
          v-if="panel === 0"
          class="shadow-none"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h5 class="m-0">
                  <v-icon class="mr-2">
                    mdi-cogs
                  </v-icon>
                  General settings
                </h5>
              </v-col>
              <v-col
                cols="1"
                class="d-flex justify-start"
              >
                <v-switch
                  v-model="configuration.breadcrumb.active"
                  hide-details="auto"
                  class="m-0 pt-0"
                />
              </v-col>
              <v-col
                cols="11"
              >
                <span class="label">
                  Breadcrumb
                </span>
              </v-col>
              <v-col
                v-if="comparison"
                cols="1"
                class="d-flex justify-start"
              >
                <v-switch
                  v-model="configuration.delta"
                  hide-details="auto"
                  class="m-0 pt-0"
                />
              </v-col>
              <v-col
                v-if="comparison"
                cols="11"
              >
                <span class="label">
                  Use delta sunburst
                </span>
              </v-col>
              <v-col
                cols="1"
                class="d-flex justify-start"
              >
                <v-switch
                  v-model="configuration.legend.active"
                  hide-details="auto"
                  class="m-0 pt-0"
                />
              </v-col>
              <v-col cols="11">
                <span class="label">
                  Legend
                </span>
              </v-col>
              <v-col
                cols="1"
                class="d-flex justify-start"
              >
                <v-switch
                  v-model="configuration.legend.colors"
                  hide-details="auto"
                  class="m-0 pt-0"
                />
              </v-col>
              <v-col cols="11">
                <span class="label">
                  Colors
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="panel === 1 && (comparison && configuration.breadcrumb.active)"
          class="shadow-none"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h5 class="m-0">
                  <v-icon class="mr-2">
                    mdi-table
                  </v-icon>
                  Breadcrumb
                </h5>
              </v-col>
              <v-col
                v-if="comparison"
                cols="5"
              >
                Delta column
              </v-col>
              <v-col
                v-if="comparison"
                cols="7"
                class="d-flex justify-start"
              >
                <v-switch
                  v-model="configuration.breadcrumb.delta"
                  hide-details="auto"
                  class="m-0 pt-0"
                />
              </v-col>
              <v-col
                v-if="comparison"
                cols="5"
              >
                Colors (comparison)
              </v-col>
              <v-col
                v-if="comparison"
                cols="7"
                class="d-flex justify-start"
              >
                <v-switch
                  v-model="configuration.breadcrumb.colors"
                  hide-details="auto"
                  class="m-0 pt-0"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ConfigurationAbstract from "@/components/Core/Solutions/Structure/Slides/Mixins/Configuration/ConfigurationAbstract.vue";

export default {
  name: 'SunburstConfiguration',
  mixins: [
    ConfigurationAbstract
  ],
  data() {
    return {
      panel: 0
    }
  },
  computed: {
    comparison() {
      return this.$store.state.shared.inputs.columns.filter(c => c.selected.mode === 'compare').length > 0
    }
  }
}
</script>