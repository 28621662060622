export class DataWaterfall {
    constructor(data, coc, type='value') {
        this.filter = [
            "Filter_Rank",
            "Filter"
        ];
        this.zoomable = [
            "Level_00",
            "Level_01",
            "Level_02"
        ];
        this.remaining = 4;
        this.current = 0;
        this.coc = coc;
        this.__summarize(data, type)
    }
    __summarize(data, type) {
        this.type = type === 'value' ? 'Value' : 'Pts_gap';
        this.__data = data;
        let i = 0;
        while (i < this.__data.length) {
            let j = 0;
            while (j < this.filter.length) {
                if (this.__data[i][this.filter[j]] == null) {
                    this.__data[i][this.filter[j]] = ""
                } else {
                    this.__data[i][this.filter[j]] = this.__data[i][this.filter[j]].toString()
                }
                j++;
            }
            j = 0;
            while (j < this.zoomable.length) {
                if (this.__data[i][this.zoomable[j]] == null) {
                    this.__data[i][this.zoomable[j]] = "";
                }
                j++;
            }
            i++;
        }
        this.__makeUnique();
    }
    getData(filter, zooms) {
        let keys = Reflect.ownKeys(filter)
        if (keys.length > 1) {
            let oldValue = filter[keys[0]]
            filter = {}
            filter[keys[0]] = oldValue
        }
        let data = this.__findBy(filter, false,true);
        let firstLevelFilter = {};
        firstLevelFilter[this.zoomable[this.current + 1]] = ''
        data = this.__findBy(firstLevelFilter, data, true)
        data = data.concat(this.__flow(filter, zooms))
        // Here we need to calc remaining;
        return this.__sort(data.filter(d => parseFloat(d[this.type]) !== 0 || d.Type === 'net' || this.getElementDepth(d) === 0))
    }
    getScope(element, data, withOthersSub = true) {
        let i = 0;
        let neighborFilter = {};
        while (i < this.filter.length) {
            neighborFilter[this.filter[i]] = element[this.filter[i]]
            i++;
        }
        i = 0;
        while (i < this.zoomable.length - 1 && element[this.zoomable[i + 1]] !== "") {
            neighborFilter[this.zoomable[i]] = element[this.zoomable[i]]
            i++;
        }
        if (withOthersSub) {
            neighborFilter[this.zoomable[i]] = false
        } else {
            neighborFilter[this.zoomable[i]] = false
            neighborFilter[this.zoomable[i + 1]] = ''
        }
        let neighbors = this.__findBy(neighborFilter, data);
        let parent = data[data.indexOf(neighbors[0]) - 1];
        return this.__sort([parent, ...neighbors])
    }
    getElementDepth(element) {
        let depth = 0;
        while (depth < this.zoomable.length - 1 && element[this.zoomable[depth + 1]] !== '') {
            depth++;
        }
        return depth;
    }
    getMaxDepth(data) {
        let maxDepth = 0;
        let i = 0;
        while (i < data.length) {
            if (this.getElementDepth(data[i]) > maxDepth) {
                maxDepth = this.getElementDepth(data[i])
            }
            i++;
        }
        return maxDepth;
    }
    getChild(element, data, sub = false, filter = false, nextLevel = false) {
        let i = 0;
        let filters = {};
        if (filter) {
            filters[Object.keys(filter)[0]] = filter[Object.keys(filter)[0]];
        } else {
            if (filter !== false) {
                filters[this.filter[0]] = '1';
            }
        }
        while (i < this.zoomable.length && element[this.zoomable[i]] !== "") {
            filters[this.zoomable[i]] = element[this.zoomable[i]]
            i++;
        }
        if (i === this.zoomable.length) {
            return [];
        }
        if (nextLevel) {
            filters[this.zoomable[i]] = '';
        } else {
            if (sub) {
                filters[this.zoomable[i - 1]] = false;
            } else {
                filters[this.zoomable[i]] = false;
            }
        }
        let child = this.__findBy(filters, data);
        return this.__sort(child);
    }
    remain(data, number = 4) {
        let i = 0;
        number -= 1;
        const maxLevels = []
        let base = {
            Value: 0,
            Total: 0
        };
        while (i <  data.length) {
            if (this.getElementDepth(data[i]) === this.zoomable.length - 1) {
                maxLevels.push(data[i])
                base = {
                    ...this.__getFilterFromElement(data[i]),
                    ...this.__getZoomableFromElement(data[i])
                }
                base[this.zoomable[this.zoomable.length - 1]] = 'remaining'
            }
            i++;
        }
        if (maxLevels.length > number) {
            base.Total = maxLevels[maxLevels.length - 1].Total
            base.Start = maxLevels[number].Start
            base[this.type] = (base.Total - base.Start).toString()
            base.unique = "waterfall-remain-" + `${maxLevels[number].Row}`
            if (base[this.type] > 0) {
                base.Type = 'in'
            } else {
                base.Type = 'out'
            }
            base.Row = maxLevels[number].Row
            base.Spliced = maxLevels.length - number
            data.splice(data.indexOf(maxLevels[number]), (maxLevels.length - number), base)
        }
        return data;
    }
    __sum(data) {
        let steps = data.filter(d => d[this.zoomable[0]] !== '' && d[this.zoomable[1]] === '')
        let i = 0;
        let start = 0;
        while (i < steps.length) {
            data[data.indexOf(steps[i])].Start = start
            if (steps[i].Type !== 'net_OoS') {
                start += parseFloat(steps[i][this.type])
            } else {
                start = parseFloat(steps[i - 1].Total)
            }
            if (steps[i].Type !== 'net' && steps[i].Type !== 'net_OoS') {
                data[data.indexOf(steps[i])].Total = start
                data = this.__sumByStep(data, steps[i]);
            } else {
                data[data.indexOf(steps[i])].Total = parseFloat(steps[i][this.type])
            }
            i++;
        }
        return data;
    }
    __flow(filter, zooms) {
        let data = this.__findBy(filter, false,true);
        let __flowingFilter = filter;
        let index = 0;
        let keys = Reflect.ownKeys(zooms)
        let __flowedData = [];
        while (index < keys.length - 1) {
            __flowingFilter[keys[index]] = zooms[keys[index]];
            __flowingFilter[keys[index + 1]] = false
            if (index < this.zoomable.length - 2) {
                __flowingFilter[this.zoomable[index + 2]] = ''
            }
            __flowedData.push(...this.__findBy(__flowingFilter, data, false))
            index++;
        }
        return __flowedData;
    }
    __makeUnique() {
        let i = 0;
        let data = this.__data;
        while (i < data.length) {
            data[i]["unique"] = "waterfall-row-" + i + (this.coc ? '-coc' : '');
            let j = 0;
            let mandatory = [...this.zoomable, ...this.filter, 'Hover', 'Label', 'line_1', 'line_2', 'line_3', 'Top_Line_Label', 'Type', 'Value', 'Row', 'Start', 'Total', 'Filter_Rank']
            while (j < mandatory.length) {
                // Static export specification, all NULL value are not present in object, so set them to blank string
                if (data[i][mandatory[j]] == null) {
                    data[i][mandatory[j]] = ''
                } else if (Array.isArray(data[i][mandatory[j]])) {
                    // Some value are encaps into bracket so we need to extract them
                    data[i][mandatory[j]] = data[i][mandatory[j]][0]
                } else if (typeof data[i][mandatory[j]] === 'object') {
                    // Some null value are converted into empty object, set them to string
                    data[i][mandatory[j]] = ''
                }
                j++;
            }
            i++;
        }
        this.__data = this.__sum(data);
    }
    __sumByStep(data, parent) {
        let stepElements = data.filter(d => d[this.zoomable[0]] === parent[this.zoomable[0]])
        let uniqueRanking = stepElements.filter(d => d[this.filter[0]] !== '').map(d => d[this.filter[0]]).filter((value, index, self) => {
            return self.indexOf(value) === index && value !== '';
        });
        let start = parent.Start;
        let i = 0;
        while (i < uniqueRanking.length) {
            let filteredData = stepElements.filter(d => d[this.filter[0]] === uniqueRanking[i])
            let filteredContainer = filteredData.filter(d => d[this.zoomable[2]] === '')
            let filteredChilds = filteredData.filter(d => d[this.zoomable[2]] !== '')
            let j = 0;
            let containerStart = start
            while (j < filteredContainer.length) {
                data[data.indexOf(filteredContainer[j])].Start = containerStart
                containerStart += parseFloat(filteredContainer[j][this.type])
                data[data.indexOf(filteredContainer[j])].Total = containerStart
                j++;
            }
            let k = 0;
            let childStart = start
            while (k < filteredChilds.length) {
                data[data.indexOf(filteredChilds[k])].Start = childStart
                childStart += parseFloat(filteredChilds[k][this.type])
                data[data.indexOf(filteredChilds[k])].Total = childStart
                k++;
            }
            i++;
        }
        return data;
    }
    __sort(data) {
        return data.sort((a, b) => {
            if (parseFloat(a.Row) < parseFloat(b.Row)) {
                return -1
            }
            if (parseFloat(a.Row) > parseFloat(b.Row)) {
                return 1
            }
            return 0;
        })
    }
    __findBy(filter, initial = false, keepStep = false) {
        let i = 0;
        let keys = Reflect.ownKeys(filter);
        let data = initial !== false ? initial : this.__data;
        while (i < keys.length) {
            data = filter[keys[i]] === false ?
                data.filter(d => d[keys[i]] !== '' || (keepStep && d[this.zoomable[0]] !== '' && d[this.zoomable[1]] === ''))
                : data.filter(d => d[keys[i]] === filter[keys[i]] || (keepStep && d[this.zoomable[0]] !== '' && d[this.zoomable[1]] === ''))
            i++;
        }
        return data;
    }
    __getZoomableFromElement(element) {
        let i = 0;
        let result = {};
        while (i < this.zoomable.length - 1 && element[this.zoomable[i]] !== '') {
            result[this.zoomable[i]] = element[this.zoomable[i]];
            i++;
        }
        result[this.zoomable[i]] = false;
        return result;
    }
    __getFilterFromElement(element) {
        let i = 0;
        let result = {};
        while (i < this.filter.length) {
            result[this.filter[i]] = element[this.filter[i]];
            i++;
        }
        return result;
    }
}

