<template>
  <div
    class="slide-configuration"
  >
    <v-row>
      <v-col
        cols="3"
        class="pl-0 pr-0 border-right"
      >
        <v-list
          dense
          hide-details="auto"
          class="m-0 pt-0"
        >
          <v-list-item
            v-b-tooltip.hover.top="'Edit general settings'"
            link
            :class="(panel === 0 ? 'active' : '') + ' pr-1'"
            @click.stop="panel = 0"
          >
            <v-list-item-icon class="mr-2">
              <v-icon>
                mdi-cogs
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                General
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-b-tooltip.hover.top="'Edit general settings'"
            link
            :class="(panel === 1 ? 'active' : '') + ' pr-1'"
            @click.stop="panel = 1"
          >
            <v-list-item-icon class="mr-2">
              <v-icon>
                mdi-axis
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Axis
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-b-tooltip.hover.top="'Edit margin & padding settings'"
            link
            :class="(panel === 2 ? 'active' : '') + ' pr-1'"
            @click.stop="panel = 2"
          >
            <v-list-item-icon class="mr-2">
              <v-icon>
                mdi-move-resize
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Margin & padding
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        cols="9"
        class="pr-0"
      >
        <v-card
          v-if="panel === 0"
          class="shadow-none"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h5 class="m-0">
                  <v-icon class="mr-2">
                    mdi-cogs
                  </v-icon>
                  General settings
                </h5>
              </v-col>
              <v-col
                cols="2"
                class="d-flex justify-start"
              >
                <v-switch
                  v-model="configuration.showlegend"
                  hide-details="auto"
                  class="m-0 pt-0"
                />
              </v-col>
              <v-col cols="10">
                <span class="label">
                  Show legends
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="panel === 1"
          class="shadow-none"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h5 class="m-0">
                  <v-icon class="mr-2">
                    mdi-axis
                  </v-icon>
                  Axis settings
                </h5>
              </v-col>
              <!--              X Axis   -->
              <v-col
                v-if="configuration.xaxis != null"
                cols="6"
              >
                <v-row class="ml-0 mr-0">
                  <v-col cols="12">
                    <strong>X</strong>
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.xaxis.showgrid"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show grid
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.xaxis.showline"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show line
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.xaxis.showticklabels"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show tick labels
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.xaxis.zeroline"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Zero line
                  </v-col>
                </v-row>
              </v-col>
              <!--              X Axis 2 -->
              <v-col
                v-if="configuration.xaxis2 != null"
                cols="6"
              >
                <v-row class="ml-0 mr-0">
                  <v-col cols="12">
                    <strong>X2</strong>
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.xaxis2.showgrid"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show grid
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.xaxis2.showline"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show line
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.xaxis2.showticklabels"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show tick labels
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.xaxis2.zeroline"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Zero line
                  </v-col>
                </v-row>
              </v-col>
              <v-divider />
              <!--              Y Axis   -->
              <v-col
                v-if="configuration.yaxis != null"
                cols="6"
              >
                <v-row class="ml-0 mr-0">
                  <v-col cols="12">
                    <strong>Y</strong>
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.yaxis.showgrid"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show grid
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.yaxis.showline"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show line
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.yaxis.showticklabels"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show tick labels
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.yaxis.zeroline"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Zero line
                  </v-col>
                </v-row>
              </v-col>
              <!--              Y Axis 2 -->
              <v-col
                v-if="configuration.yaxis2 != null"
                cols="6"
              >
                <v-row class="ml-0 mr-0">
                  <v-col cols="12">
                    <strong>Y2</strong>
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.yaxis2.showgrid"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show grid
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.yaxis2.showline"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show line
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.yaxis2.showticklabels"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Show tick labels
                  </v-col>
                  <v-col
                    cols="2"
                    class="d-flex justify-start"
                  >
                    <v-switch
                      v-model="configuration.yaxis2.zeroline"
                      dense
                      hide-details="auto"
                      class="m-0 pt-0"
                    />
                  </v-col>
                  <v-col cols="10">
                    Zero line
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="panel === 2"
          class="shadow-none"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h5 class="m-0">
                  <v-icon class="mr-2">
                    mdi-move-resize
                  </v-icon>
                  Margin & padding settings
                </h5>
              </v-col>
              <v-col cols="3">
                <span class="label">
                  Left
                </span>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="configuration.margin.l"
                  hide-details="auto"
                  class="m-0 pt-0"
                  type="number"
                  min="0"
                />
              </v-col>
              <v-col cols="3">
                <span class="label">
                  Right
                </span>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="configuration.margin.r"
                  hide-details="auto"
                  class="m-0 pt-0"
                  type="number"
                  min="0"
                />
              </v-col>
              <v-col cols="3">
                <span class="label">
                  Top
                </span>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="configuration.margin.t"
                  hide-details="auto"
                  class="m-0 pt-0"
                  type="number"
                  min="0"
                />
              </v-col>
              <v-col cols="3">
                <span class="label">Bottom</span>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="configuration.margin.b"
                  hide-details="auto"
                  class="m-0 pt-0"
                  type="number"
                  min="0"
                />
              </v-col>
              <v-col cols="3">
                <span class="label">Padding</span>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="configuration.margin.pad"
                  hide-details="auto"
                  class="m-0 pt-0"
                  type="number"
                  min="0"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--              Margins   -->
  </div>
</template>
<script>
import ConfigurationAbstract from "@/components/Core/Solutions/Structure/Slides/Mixins/Configuration/ConfigurationAbstract.vue";

export default {
  name: 'PlotlyConfiguration',
  mixins: [
    ConfigurationAbstract
  ],
  data() {
    return {
      panel: 0
    }
  }
}
</script>