<template>
  <div class="datama-loading-bar">
    <div class="message-container">
      {{ message }}
    </div>
    <div class="loading-bar-container">
      <v-progress-linear
        v-model="value"
        :active="show"
        :indeterminate="query"
        :color="color"
        :query="true"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingBarToast',
  props: {
    value: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      default: false
    },
    query: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: "#00484A"
    }
  },
}
</script>
<style lang="scss" scoped>
.message-container {
  margin-bottom: 10px;
}

.loading-bar-container {
  min-height: 6px;
}
</style>