const CryptoJS = require("crypto-js")

class VuexStoreCache {
    /**
        derived state means a bunch of states grouped together in the store via a getter and have a mutation available to do the setting
        saying save is the same as saying persist 
    */
    constructor() {
        /**
        this.persistedDerivedStates : MAP(
            key = name:String,
            value={
                group:["state1"...] // group of states defined in the store
                getter:string // VuexGetter name can be enhanced with extra ...arguments
                setter:string // VuexAction can be enhanced with extra ...arguments
                watcher:string // VuexGetter name that tells you if anychanges happened to a derived state
                beforePersist: function
                afterPersist : function
                stateStack:[{
                    hash:hash(data),
                    data:data
                    }
                ]
                // newChanges:0 exists in the store
            }
        );
        we use MAP to minimise lookup time
        The state,getter and setter names are the same as the ones defined in the vuex sotre be carefull when working with modules! example:
        if I defined a state in module1 the group array should have ["module1/state1","module1/state2"....] same goes for getters and setters (hopefully in the third iteration we won't need to write getters directly since the factory class wil take care of that)
        */
        //? removing a derivedState is not possible due to the randomness factor it might introduce
        this.store = null
        this.persistedDerivedStates = new Map()
    }

    linkStore(store) {
        this.store = store
    }

    flatten(obj) {
        // this function is used to add direct accessors to deeply nested properties and example would be k=flatten({x:[0,{z:{y:1}}])
        // k["x[1].z.y"] -> 1
        //! warning don't use this on deeply nested objects (15 levels or more)
        let root = {};
        (function tree(obj, index) {
            let suffix = toString.call(obj) === "[object Array]" ? "]" : "";
            for (let key in obj) {
                if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;
                root[index + key + suffix] = obj[key];
                if (toString.call(obj[key]) === "[object Array]") tree(obj[key], index + key + suffix + "[");
                if (toString.call(obj[key]) === "[object Object]") tree(obj[key], index + key + suffix + ".");
            }
        })(obj, "");
        return root;
    }

    dispatch(actionName, ...actionArgs) {
        return this.store.dispatch(actionName, ...actionArgs);
    }

    get(getterName, type = "getter") {
        if (type === "getter") {
            return this.store.getters[getterName]
        } else if (type === "state")
            return this.store.state[getterName]
    }

    storeGetter(getterName, ...getterArgs) {
        if (getterArgs !== null && getterArgs.length !== 0) return this.store.getters[getterName](...getterArgs);
        else return this.store.getters[getterName].getters[getterName]
    }

    addDerivedState(
        name,
        group,
        getterName = "",
        setterName = "",
        beforePersist = () => {},
        afterPersist = () => {},
        override = false
    ) {
        const derivedState = {
            group: group,
            getter: getterName,
            setter: setterName,
            beforePersist: (typeof beforePersist == "function") ? beforePersist : () => {
            },
            afterPersist: (typeof afterPersist == "function") ? afterPersist : () => {
            },
            // can add beforeClean & afterClean here
            stateStack: []
        }
        if (override === true) this.removePersistedState(name)
        this.persistedDerivedStates.set(name, derivedState);
    }

    getDerivedStateObject(derivedStateName) {
        return this.persistedDerivedStates.get(derivedStateName);
    }

    getPersistedDerivedStates() {
        return this.persistedDerivedStates
    }

    pushStackDerivedStateData(derivedStateName, data, ...getterArgs) {
        // the watcher catches the initialisation of states so make sure not to push any empty data to the stack
        let flag = 0;
        for (const key in data) {
            if (data[key] && data[key].length > 0) flag++;
        }
        if (flag === 0) {
            // all the sates available in the data are empty so we don't push them into the stack
            return
        }
        const derivedStateObject = this.getDerivedStateObject(derivedStateName);
        if (data == null) {
            data = this.storeGetter(derivedStateObject.getter, ...getterArgs)
        }

        const res = CryptoJS.SHA1(JSON.stringify(data))
        const hash = CryptoJS.enc.Hex.stringify(res);
        /* watch for duplicated state (since a watcher gets triggered everytime a change in reference or value happens so if i say
        state1 = [] the watcher is triggered if say state1 = [] anothertime the watcher will get triggered once again because memory adress of the first
        empty list is not the same as the second
        )
        duplicated state: if the previousState(i-1) == currentState(i) 
        */
        const lastIndex = derivedStateObject.stateStack.length - 1
        if (lastIndex >= 0 && derivedStateObject.stateStack[lastIndex].hash === hash) {
            return
        }
        if (lastIndex >= 0) this.store.state.cache_changes[derivedStateName] += (hash !== derivedStateObject.stateStack[0].hash)//!refacto: use mutation instead
        derivedStateObject.stateStack.push(
            {
                hash: hash,
                data: data
            }
        );
    }

    cleanStack(derivedStateName, limit = 0) {
        this.store.state.cache_changes[derivedStateName] = 0
        while (this.getDerivedStateObject(derivedStateName).stateStack.length > limit) {
            this.getDerivedStateObject(derivedStateName).stateStack.pop()
        }

    }

    /* you can still retrieve data from this class but using the browser cache ensures that the derivedStates are persisted across the whole session. unless you clean the state stack*/
    saveToCache(derivedStateName, beforePersistArgumentsArray = [], afterPersistArgumentsArray = [], cleanStateStack = false) {
        const derivedState = this.getDerivedStateObject(derivedStateName)
        if (derivedState != null) {
            derivedState.beforePersist(...beforePersistArgumentsArray)
            const stackHead = derivedState.stateStack[derivedState.stateStack.length - 1]
            if (stackHead && stackHead !== {} && stackHead.data) {

                localStorage.setItem(derivedStateName, JSON.stringify(
                    derivedState.stateStack[derivedState.stateStack.length - 1]
                ))
            } else {
                localStorage.setItem(derivedStateName, JSON.stringify([]))
            }
            if (cleanStateStack === true) {
                // a fast way to clean an array in JS
                derivedState.stateStack.length = 0
            }
            //derivedState.newChanges = 0;
            derivedState.afterPersist(...afterPersistArgumentsArray)
        }
    }

    removeFromStorage(derivedStateName) {
        // can use beforeClean & afterClean here 
        localStorage.removeItem(derivedStateName)
    }

    retreiveFromStorage(derivedStateName) {
        const cachedDerivedState = localStorage.getItem(derivedStateName);
        if (cachedDerivedState !== null && cachedDerivedState != null && cachedDerivedState.length !== 0) {
            return JSON.parse(cachedDerivedState).data
        }
    }

    retreiveHashFromStorage(derivedStateName) {
        const cachedDerivedState = localStorage.getItem(derivedStateName);
        if (cachedDerivedState !== null && cachedDerivedState != null && cachedDerivedState.length !== 0) {
            return JSON.parse(cachedDerivedState).hash
        }
    }

    async loadFromStorage(derivedStateName) {
        const derivedStateObject = this.getDerivedStateObject(derivedStateName);
        const data = this.retreiveFromStorage(derivedStateName);
        await this.dispatch(derivedStateObject.setter, data[derivedStateObject.group[0]]);
        this.pushStackDerivedStateData(derivedStateName, data);
        return data[derivedStateObject.group[0]]//for now
    }

    hasNewChanges(derivedStateName) {
        return this.store.state.cache_changes[derivedStateName]
    }

    hasUnsavedChanges(derivedStateName, hash) {
        const derivedStateObject = this.getDerivedStateObject(derivedStateName)
        const len = derivedStateObject.stateStack.length
        if (len >= 1) return derivedStateObject.stateStack[len - 1].hash !== hash
        return false
    }

    resetChanges(derivedStateName) {
        // only used for saving
        this.removeFromStorage(derivedStateName)
        this.store.state.cache_changes[derivedStateName] = 1
    }

    groupSetter(state, group, payload) {
        for (let i = 0; i < group.length; i++) {
            const stateQueue = group[i].split("/")
            const moduleName = stateQueue[0]
            const stateName = stateQueue[1]
            state[moduleName][stateName] = payload[group[i]]
        }
        return payload
    }

    groupGetter(state, group) {
        const payload = {}
        for (let i = 0; i < group.length; i++) {
            let groupState = {...state};
            const stateQueue = group[i].split("/");
            for (let j = 0; j < stateQueue.length; j++) {
                if (groupState == null) break;
                groupState = groupState[stateQueue[j]]
            }
            payload[group[i]] = groupState;
        }
        return payload
    }

    removePersistedState(derivedStateName) {
        // will almost never be used but just in case you need a deepClean
        this.persistedDerivedStates.delete(derivedStateName)
    }

    removeAllPersistedStates() {
        // will almost never be used but just in case you need a deepClean
        this.persistedDerivedStates.clear()
    }

    generateCacheMethods(vuexCache) {
        for (const derivedState of this.getPersistedDerivedStates()) {
            const derivedStateName = derivedState[0];
            const derivedStateObject = derivedState[1];
            const cleanedName = derivedStateName.replace("-", "_")

            const autoGetterName = cleanedName + "_getter";
            if (!(typeof derivedStateObject.getter == "string" && derivedStateObject.getter !== "")) {
                // this code allows us to name a function instead of keeping it anonymous
                const {[autoGetterName]: getter} = {
                    [autoGetterName]: (state) => {
                        // carefull state here doesn't have direct getters such as prep/smth... so we do it ourselves
                        return CacheManager.groupGetter(state, derivedStateObject.group);
                    }
                }
                vuexCache.getters[autoGetterName] = getter
                derivedStateObject.getter = autoGetterName
            }
            const autoSetterName = cleanedName + "_setter";
            if (!(typeof derivedStateObject.setter == "string" && derivedStateObject.setter !== "")) {
                const {[cleanedName.toUpperCase(cleanedName) + "_MUTATION"]: mutation} = {
                    [cleanedName.toUpperCase(cleanedName) + "_MUTATION"]: (state, payload) => {
                        return CacheManager.groupSetter(state, derivedStateObject.group, payload)
                    }
                }
                const {[autoSetterName]: setterAction} = {
                    [autoSetterName]: ({commit}, payload) => {
                        commit(cleanedName.toUpperCase(cleanedName) + "_MUTATION", payload)
                    }
                }
                vuexCache.mutations[cleanedName.toUpperCase(cleanedName) + "_MUTATION"] = mutation
                vuexCache.actions[autoSetterName] = setterAction
                derivedStateObject.setter = autoSetterName
            }
            const changeListenerName = cleanedName + "_changes";
            const {[changeListenerName]: watcher} = {
                [changeListenerName]: (state) => {
                    // carefull state here doesn't have direct getters such as prep/smth... so we do it ourselves
                    return state["cache_changes"][derivedStateName]
                }
            }
            if (vuexCache.state["cache_changes"] == null) {
                vuexCache.state["cache_changes"] = {}
            }
            vuexCache.state["cache_changes"][derivedStateName] = 0
            vuexCache.getters[changeListenerName] = watcher
            derivedStateObject.watcher = changeListenerName
        }
    }

    stackLength(derivedStateName) {
        return this.getDerivedStateObject(derivedStateName).stateStack.length
    }
}

const CacheManager = new VuexStoreCache()
export default CacheManager