<script>
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
export default {
  name: 'ViewAbstract',
  mixins: [
    DataMaLogicAbstract
  ],
  computed: {
  }
}
</script>