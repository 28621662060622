<template>
  <v-card
    v-if="member.user"
    class="w-100"
  >
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <div class="ml-3">
          <div class="d-flex align-center">
            <span
              class="hover-field"
            >{{ member.user.name }}<v-icon
              small
              class="ml-1"
              @click.prevent="copyToClipboard(member.user.name)"
            >
              mdi-content-copy
            </v-icon></span>
          </div>
          <div style="font-size: smaller; text-align: left">
            <span class="hover-field">Membership id : {{ member.id }}<v-icon
              small
              class="ml-1"
              @click.prevent="copyToClipboard(member.id)"
            >
              mdi-content-copy
            </v-icon></span><br>
            <span class="hover-field">UUID : {{ member.user.uid }}<v-icon
              small
              class="ml-1"
              @click.prevent="copyToClipboard(member.user.uid)"
            >
              mdi-content-copy
            </v-icon></span><br>
            <span class="hover-field">email : {{ member.user.email }}<v-icon
              small
              class="ml-1"
              @click.prevent="copyToClipboard(member.user.email)"
            >
              mdi-content-copy
            </v-icon></span><br>
            Member of the org since: {{ new Date(member.created_at).toLocaleDateString() }}<br>
            Datama user since: {{ new Date(member.user.created_at).toLocaleDateString() }}
          </div>
        </div>
      </div>
      <div
        v-if="edit === true"
        style="display: flex; align-items: center;"
      >
        <DeleteMemberModal
          :member="member"
          admin-panel
        />
      </div>
    </v-card-title>
  </v-card>
  <v-card
    v-else
    class="w-100"
  >
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <div class="ml-3">
          <div class="d-flex align-center">
            <span class="hover-field">
              {{ member.name }}
              <v-icon
                small
                class="ml-1"
                @click.prevent="copyToClipboard(member.name)"
              >
                mdi-content-copy
              </v-icon>
            </span>
          </div>
          <div style="font-size: smaller; text-align: left">
            <span class="hover-field">
              uid: {{ member.uid }}
              <v-icon
                small
                class="ml-1"
                @click.prevent="copyToClipboard(member.uid)"
              >
                mdi-content-copy
              </v-icon>
            </span>
            <br>
            <span class="hover-field">
              Email: {{ member.email }}
              <v-icon
                small
                class="ml-1"
                @click.prevent="copyToClipboard(member.email)"
              >
                mdi-content-copy
              </v-icon>
            </span>
            <br>
            User since: {{ new Date(member.created_at).toLocaleDateString() }}
          </div>
        </div>
      </div>
      <div
        style="display: flex; align-items: center; flex-direction: column; gap: 10px"
      >
        <ManageUserRolesModal
          class="mx-2"
          :user="member"
        />
        <ImpersonateUser
          class="mx-2"
          :selected-user="member"
        />
        <ManageTrialModal
          class="mx-2"
          :user="member"
        />
        <!--        <v-btn-->
        <!--          icon-->
        <!--          class="text-danger shadow-none transparent"-->
        <!--          @click="editUser(member)"-->
        <!--        >-->
        <!--          <v-icon>mdi-pencil-outline</v-icon>-->
        <!--        </v-btn>-->
        <!--        <v-btn-->
        <!--          icon-->
        <!--          class="text-dark shadow-none transparent"-->
        <!--          @click="deleteUser(member)"-->
        <!--        >-->
        <!--          <v-icon>mdi-delete-outline</v-icon>-->
        <!--        </v-btn>-->
      </div>
    </v-card-title>
  </v-card>
</template>
<script>
import ViewAbstract from "@/views/Mixins/ViewAbstract.vue";
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import DeleteMemberModal from "@/components/Home/Org/Modals/DeleteMemberModal.vue";
import ManageUserRolesModal from "@/components/Home/Admin/Modals/ManageUserRolesModal.vue";
import ImpersonateUser from "@/components/Home/Admin/Modals/ImpersonateUser.vue";
import ManageTrialModal from "@/components/Home/Admin/Modals/ManageTrialModal.vue";

export default {
  name: 'MemberCard',
  components: {ManageTrialModal, ImpersonateUser, ManageUserRolesModal, DeleteMemberModal},
  mixins: [
    ViewAbstract,
    OrgAbstract
  ],
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    member: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {
    editUser() {
      //TODO : Add editUser
      this.registerToast(this.$toast.success(`Not available yet.`, {timeout: 3000}))
    },
    deleteUser() {
      //TODO : Add deleteUser
      this.registerToast(this.$toast.success(`Not available yet.`, {timeout: 3000}))
    }
  }
}
</script>

<style scoped lang="scss">
.hover-field .v-icon {
  display: none;
}

.hover-field:hover .v-icon {
  display: inline-block;
}
</style>