<template>
  <v-card
    v-if="(title || html) && inline"
    class="datama-pro-tips text-left"
  >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <div v-html="html">
        {{ html }}
      </div>
    </v-card-text>
  </v-card>
  <div
    v-else-if="(title || html)"
    class="position-fixed"
    :style="'z-index: 100000;left: ' + left + 'px;top: ' + top + 'px;' + (clickable ? 'cursor: pointer;' : '')"
  >
    <v-card
      class="text-left datama-pro-tips"
    >
      <v-card-title class="mb-1 pb-0">
        {{ title }}
      </v-card-title>
      <v-card-text class="mt-0 pt-0">
        <div v-html="html">
          {{ html }}
        </div>
        <div
          v-if="clickable"
          class="small italic"
          style="font-weight: 500"
        >
          Click to open related documentation
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'ProTips',
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    target: {
      type: [String, Boolean],
      default: false
    },
    title: {
      type: [String, Boolean],
      default: false
    },
    html: {
      type: [String, Boolean],
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    left() {
      return document.getElementById(this.target).getBoundingClientRect().left
    },
    top() {
      return document.getElementById(this.target).getBoundingClientRect().top + document.getElementById(this.target).offsetHeight
    }
  }
}
</script>
<style lang="scss">
@import '@/theme/config/_colors.scss';
#app {
  .datama-pro-tips {
    box-shadow: 0 10px 20px rgba(0,0,0,0.3);
    border: 1px solid rgba(0,0,0,0.2);
    max-width: 400px;
    word-break: break-word;
    & .v-card__text {
      //border-left: 5px $datagreen solid;
      & pre {
        background-color: #444;
        color: #F6F6F6;
        padding: 10px;
        display: table;
        width: 100%;
        & > code {
          padding: 10px;
          border-radius: 5px;
          border: 1px solid white;
          display: block;
          margin: 0;
        }
      }
    }
    & .v-card__title {
      font-size: 16px;
      //border-left: 5px $datagreen solid;
    }
  }
}
</style>