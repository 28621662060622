<!--eslint-disable vue/no-mutating-props-->
<template>
  <v-dialog
    :value="show"
    width="300px"
  >
    <template #activator="{ on, attrs }">
      <slot
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-card class="text-left">
      <v-card-title>
        Confirmation
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions class="pt-0 justify-end">
        <v-btn
          small
          class="transparent shadow-none close"
          style="min-width: unset"
          @click.stop="no"
        >
          <v-icon
            class="mr-1"
            small
          >
            mdi-close
          </v-icon>
          close
        </v-btn>
        <v-btn
          small
          class="shadow-none transparent text-primary ok"
          style="min-width: unset"
          @click.stop="yes"
        >
          <v-icon
            class="mr-1"
            small
          >
            mdi-check
          </v-icon>
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "TooltipYesNo",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      required: true
    },
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
  },
  computed: {
    position() {
      if (this.top) {
        return "top"
      }
      if (this.bottom) {
        return "bottom"
      }
      if (this.right) {
        return "right"
      }
      if (this.left) {
        return "left"
      }
      return ""
    }
  },
  methods: {
    yes() {
      this.$emit('yesClicked')
    },
    no() {
      this.$emit('noClicked')
    }
  }
}
</script>
<style lang="scss">
@import "@/theme/config/_colors.scss";
#app {
  & > .tooltip-component {
    pointer-events: auto !important;
    min-height: unset !important;
    width: fit-content !important;
    max-width: unset !important;
    background: white !important;
    color: black;
    opacity: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    line-height: 30px;
    padding: 0 10px 0 10px;
    transform: translateX(50%);
    box-shadow: 5px 10px 15px rgba(0,0,0,0.1);
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    text-align: left;
    & > span {
      display: inline-block !important;
      width: fit-content;
    }
    & > div {
      justify-content: flex-end;
    }
    &::before {
      border-right: solid 8px transparent;
      border-left: solid 8px transparent;
      position: absolute;
      z-index: -21;
      content: '';
      height: 0;
      width: 0;
      top: 100%;
      left: 50%;
    }
    &.tooltip-top {
      &::before {
      }
    }
    &.tooltip-bottom {
      &::before {
      }
    }
    &.tooltip-left {
      &::before {
      }
    }
    &.tooltip-right {
      &::before {
      }
    }
    &.error .v-btn .ok{
      color: #ff6060 !important;
    }
    &.success .v-btn.ok {
      color: #1B5E20 !important;
    }
    &.secondary .v-btn.close {
      color: #3f3f3f !important;
    }
    &.primary .v-btn.ok {
      color: $primary !important;
    }
    &.info .v-btn.ok {
      color: #2196f3 !important;
    }
  }
}
</style>