<template>
  <div
    v-if="(message != null && message !== '') && type != null"
    :class="getClass()"
    style="position: relative;min-height: 40px;margin-bottom: 0.5rem;"
  >
    <transition name="shrink">
      <v-alert
        v-if="state"
        dense
        text
        dismissible
        rounded
        :type="type"
        :class="'datama_warning datama_warning_' + colors[type]"
        @input="$emit('dismiss')"
      >
        <span v-html="message" />
        <DocumentationLink
          v-if="docs != null"
          :title="title"
          :url="docs"
          :pro-tips="proTips"
        />
      </v-alert>
    </transition>
    <transition name="shrink">
      <v-btn
        v-if="!state"
        :class="['datama_warning_toggle ', 'datama_warning_' + colors[type]]"
        @click.stop="$emit('display')"
      >
        <v-icon v-if="type === 'success'">
          mdi-check
        </v-icon>
        <v-icon v-else-if="type === 'info'">
          mdi-alert-circle-outline
        </v-icon>
        <v-icon v-else-if="type === 'warning'">
          mdi-alert-outline
        </v-icon>
        <v-icon v-else-if="type === 'error'">
          mdi-alert-octagon-outline
        </v-icon>
      </v-btn>
    </transition>
  </div>
</template>
<script>
import DocumentationLink from "@/components/Core/Structure/Misc/DocumentationLink.vue";
export default {
  name: 'SlideWarning',
  components: {
    DocumentationLink
  },
  props: {
    type: {
      type: String,
      default() {
        return 'info'
      }
    },
    message: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default() {
        return true;
      }
    },
    docs: {
      type: String,
      default() {
        return null
      }
    },
    title: {
      type: String,
      default() {
        return null
      }
    },
    proTips: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      colors: {
        info: 'blue',
        warning: 'orange',
        error: 'red',
        success: 'green'
      }
    }
  },
  methods: {
    getClass() {
      let classNames = ['d-flex', 'flex-nowrap', 'justify-content-end'];
      if (!this.state) {
        classNames.push('text-right')
        classNames.push('warning-badge')
      }
      return classNames;
    }
  }
}
</script>
<style lang="scss">
@import '@/theme/config/_colors.scss';
#app {
  .v-btn {
    &.datama_warning_toggle {
      width: 40px !important;
      height: 40px !important;
      font-size: 20px;
      border-radius: 50%;
      min-width: unset;
      color: white;
      text-align: center;
      position: absolute;
      right: 0;
      box-shadow: none !important;
      background: transparent;
      &::before {
        position: absolute;
        display: inline-block;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        background: currentColor;
        opacity: 0.25;
        content: "";
      }
    }
  }
  .datama_warning_blue {
    color: var(--bloc-color-source) !important;
    caret-color: var(--bloc-color-source) !important;
  }
  .datama_warning_orange {
    color: $dataendorange !important;
    caret-color: $dataendorange !important;
  }
  .datama_warning_red {
    color: $datared !important;
    caret-color: $datared !important;
  }
  .datama_warning_green {
    color: $primary !important;
    caret-color: $primary !important;
  }
  .datama_warning {
    width: 100%;
    & ul, & li {
      margin: 0 !important;
    }
    & .v-alert__content {
      font-size: 12px !important;
      & > a {
        font-size: 14px;
        margin-left: 5px;
        color: currentColor;
        transform: translateY(-3px);
        display: inline-block;
      }
    }
  }
}
</style>