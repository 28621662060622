<script>
export default {
  name: 'ConfigurationAbstract',
  props: {
    configuration: {
      type: Object,
      default() {
        return null
      }
    }
  }
}
</script>