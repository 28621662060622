<!--    <v-card>-->
<!--      <v-toolbar dark :color="options.color" dense flat>-->
<!--        <v-toolbar-title class="white&#45;&#45;text">{{ title }}</v-toolbar-title>-->
<!--      </v-toolbar>-->
<!--      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>-->
<!--      <v-card-actions class="pt-0">-->
<!--        <v-spacer/>-->
<!--        <v-btn color="primary darken-1" text @click.native="agree">Yes</v-btn>-->
<!--        <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>-->
<!--      </v-card-actions>-->
<!--    </v-card>-->

<template> 
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title class="text-h5 mb-0 pb-0">
        <v-text-field
          v-model="selected_context.name"
          placeholder="Context Title"
          :rules="[rules.required]"
          plain
          flat
          solo
          single-line
          class="ma-0 pa-0 no_underline font-weight-bold text-h5"
        />
        <!--        <v-combobox-->
        <!--          v-model="selected_context"-->
        <!--          :filter="filter"-->
        <!--          :hide-no-data="!search"-->
        <!--          :items="context_list"-->
        <!--          :search-input.sync="  search"-->
        <!--          hide-selected-->
        <!--          label="Search for an option"-->
        <!--          multiple-->
        <!--          :rules="[rules.required]"-->
        <!--          solo-->
        <!--          flat-->
        <!--          single-line-->
        <!--          elevation="0"-->
        <!--          class="ma-0 elevation-0 pa-0 no_underline font-weight-bold text-h5"-->
        <!--        >-->
        <!--          <template v-slot:no-data>-->
        <!--            <v-list-item>-->
        <!--              <span class="subheading">Create </span>-->
        <!--              &nbsp;<v-chip-->
        <!--                :color="`${colors[nonce - 1]} lighten-3`"-->
        <!--                label-->
        <!--                small-->
        <!--              >{{ search }}-->
        <!--              </v-chip>-->
        <!--            </v-list-item>-->
        <!--          </template>-->
        <!--        </v-combobox>-->
      </v-card-title>

      <v-card-text style="padding-bottom:0;margin:0; margin-top:5%;">
        <v-textarea
          v-model="selected_context.value"
          clearable
          :rules="[rules.required]"
          auto-grow
          flat
          row="3"
          solo
          name="input-7-4"
          label="Write your context here"
          class="ma-0 pa-0 no_underline"
          placeholder="Write your context, e.g : «It's was black friday last week», or «We just launch product A in september»"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="primary"
          text
          @click.native="cancel"
        >
          Cancel
        </v-btn>

        <v-btn
          rounded
          dark
          color="primary"
          style="color:white !important;"
          class="text-white"
          :disabled="!isFormValid"
          @click.stop="create"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <ConsentPopupLicense ref="confirm"></ConsentPopupLicense>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <ConsentPopupLicense ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  name: "AddContextPopup",
  data: () => ({
    activator: null,
    selected_context:{
      id:0,
      name:"",
      value:""
    },
    attach: null,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
    editing: null,
    editingIndex: -1,
    items: [
      { header: 'Select an option or create one' },
      {
        text: 'Foo',
        color: 'blue',
      },
      {
        text: 'Bar',
        color: 'red',
      },
    ],
    nonce: 1,
    menu: false,
    model: [
      {
        text: 'Foo',
        color: 'blue',
      },
    ],
    x: 0,
    search: null,
    y: 0,
    dialog: false,
    resolve: null,
    reject: null,
    context: "",
    title: "",
    rules: {
      required: value => !!value || 'Required.',
    },
    options: {
      color: 'primary',
      width: "50%",
      zIndex: 200
    }
  }),
  computed: {
    isFormValid() {
      if((this.selected_context.name !== null && this.selected_context.name.trim() !== "") &&
         (this.selected_context.value !== null && this.selected_context.value.trim() !== ""))
      {
        return true
      }
      return false
    },
  },
  methods: {
    /**
     * Returns a hash code for a string.
     * (Compatible to Java's String.hashCode())
     *
     * The hash code for a string object is computed as
     *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
     * using number arithmetic, where s[i] is the i th character
     * of the given string, n is the length of the string,
     * and ^ indicates exponentiation.
     * (The hash value of the empty string is zero.)
     *
     * @param {string} s a string
     * @return {number} a hash code value for the given string.
     */
    hashCode(s) {
      var h = 0, l = s.length, i = 0;
      if ( l > 0 )
        while (i < l)
          h = (h << 5) - h + s.charCodeAt(i++) | 0;
      return h;
    },
    open() {
      this.dialog = true
      this.selected_context = {
        id:0,
        name:"",
        value:""
      }
      // this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    create() {
      this.resolve({
        "id":this.hashCode(this.selected_context.name),
        "name":this.selected_context.name,
        "value":this.selected_context.value
      })
      this.dialog = false
    },
    cancel() {
      this.resolve(undefined)
      this.dialog = false
    }
  }
}
</script>
<style scoped>
.no_underline >>> .v-input__slot::before {
  border-style: none !important;
}
</style>