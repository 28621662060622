<template>
  <v-card class="w-100">
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <OrgAvatar
          :editable="false"
          :size="60"
          :org="org"
        />
        <div class="ml-3">
          <div class="d-flex align-center">
            <span class="hover-field">
              {{ org.name }}
              <v-icon
                small
                class="ml-1"
                @click.prevent="copyToClipboard(org.name)"
              >
                mdi-content-copy
              </v-icon>
            </span>
          </div>
          <div style="font-size: smaller; text-align: left">
            Creation date: {{ new Date(org.created_at).toLocaleDateString() }}<br>
            <span class="hover-field">
              oid: {{ org.oid }}
              <v-icon
                small
                class="ml-1"
                @click.prevent="copyToClipboard(org.oid)"
              >
                mdi-content-copy
              </v-icon>
            </span>
          </div>
        </div>
      </div>
      <div style="display: flex; align-items: center;">
        <ManageTeamModal
          :org="org"
          edit
        />
        <DeleteOrgModal
          :org="org"
          admin-panel
        />
      </div>
    </v-card-title>
  </v-card>
</template>
<script>
import ManageTeamModal from "@/components/Home/Admin/Modals/ManageTeamModal.vue";
import OrgAvatar from "@/components/Home/Org/Modals/OrgAvatar.vue";
import ViewAbstract from "@/views/Mixins/ViewAbstract.vue";
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import jwt from "jsonwebtoken";
import DeleteOrgModal from "@/components/Home/Org/Modals/DeleteOrgModal.vue";

export default {
  name: 'OrganizationCard',
  components: {DeleteOrgModal, OrgAvatar, ManageTeamModal},
  mixins: [
    ViewAbstract,
    OrgAbstract
  ],
  props: {
    org: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      search: '',
      deleteOrgBusy: false
    };
  },
  computed: {},
  methods: {}
}
</script>

<style scoped lang="scss">
.hover-field .v-icon {
  display: none;
}

.hover-field:hover .v-icon {
  display: inline-block;
}
</style>