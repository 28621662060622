<template>
  <div>
    <v-switch
      v-model="doSelectPeriod"
      label="Select a Period"
      @change="checkEmit"
    />
    <!--    <label for="periodPiano">Select a Period</label>-->
    <div
      v-if="doSelectPeriod"
    >
      <v-select
        v-model="value.period"
        :items="[{value: 'week', text: 'Last Week'}, {value: 'month', text: 'Last Month'}, {value: 'year', text: 'Last Year'}, {value: 'custom', text: 'Custom'}]"
        :readonly="readonly"
        @change="checkEmit"
      />
      <v-switch
        v-if="value.period === 'last_d_days' || value.period === 'custom'"
        v-model="value.UseSpecificDates"
        :readonly="readonly"
        label="Use specific dates"
        @change="checkEmit"
      />
      <div v-if="value.period === 'custom' && value.UseSpecificDates">
        <div class="form-line">
          <label for="date1">Start</label>
          <input
            id="date1"
            v-model="value.date1"
            type="date"
            :max="maxDate"
            name="date1"
            :readonly="readonly"
            @change="checkEmit"
          >
        </div>
        <div class="form-line">
          <label for="date2">End</label>
          <input
            id="date2"
            v-model="value.date2"
            type="date"
            :max="maxDate"
            name="date2"
            :readonly="readonly"
            @change="checkEmit"
          >
        </div>
      </div>
      <div v-if="value.period === 'custom' && !value.UseSpecificDates">
        <div class="input-date-element number">
          <label for="period">Last #d days</label>
          <input
            id="period"
            v-model="value.lastDDays"
            type="number"
            min="1"
            name="period"
            placeholder="Select a number"
            :readonly="readonly"
            @change="checkEmit"
          >
        </div>
        <small v-show="value.lastDDays !== ''">
          <span class="input-date-label">
            (Last {{ value.lastDDays }} Days)
          </span>
        </small>
      </div>

      <v-switch
        v-if="value.period === 'custom'"
        v-model="value.moveTimeWindow"
        :readonly="readonly"
        label="Move the time window overtime"
        @change="checkEmit"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "DatePianoInput",
  // eslint-disable-next-line vue/require-prop-types
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    startValue: {
      type: Object,
      default() {
        return null
      }
    }
  }, // TODO: use props instead of ref with a nextTick in properties
  data() {
    return {
      value: {
        period: "month",
        UseSpecificDates: false,
        moveTimeWindow: true,
        date1: "",
        date2: "",
        lastDDays: 1,
      },
      date_cnf: {
        date_model: "preset",
        date: "last_month",
      },
      doSelectPeriod: true,
    }
  },
  computed: {
    maxDate() {
      return moment().format('YYYY-MM-DD');
    }
  },
  watch: {
    startValue: {
      handler(value) {
        if (value) {
          if (value.period)
            this.value.period = n.period
          if (value.lastDDays)
            this.value.lastDDays = n.lastDDays
          if (value.date1)
            this.value.date1 = n.date1
          if (value.date2)
            this.value.date2 = n.date2
        }
      },
      deep: true,
    },
    value: {
      handler() {
        this.getPeriodGoogle()
        this.checkEmit()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.startValue) {
      this.periodFromConfig()
      // this.getPeriodGoogle()
      this.$emit("initialisation", {...this.date_cnf, ...this.value, doSelectPeriod: this.doSelectPeriod}); //this.value is to Make the app store values to reuse them if needed
    }
  },
  methods: {
    checkEmit() {
      //Check before emit
      this.getPeriodGoogle()
      this.emitChange() //emit the changes
    },
    periodFromConfig() {
      let config = this.startValue
      if ("doSelectPeriod" in config) {
        this.doSelectPeriod = config.doSelectPeriod
      }
      if (config.date && config.date_model) {
        if (config.date_move_time_window) {
          this.moveTimeWindow = config.date_move_time_window
        } else {
          this.moveTimeWindow = true
        }
        if (config.date_model) {
          if (config.date_model === "preset") {
            switch (config.date) {
              case "last_week":
                this.value.period = "week";
                break;
              case "last_month":
                this.value.period = "month";
                break;
              case "last_year":
                this.value.period = "year";
                break;
              default:
                this.value.period = "custom"
                if (!Array.isArray(config.date)) {
                  this.value.lastDDays = config.date.split("_")[1];
                }
                if (Array.isArray((config.date))) {
                  this.value.UseSpecificDates = true
                  if (this.moveTimeWindow) {
                    let d1 = config.date[0].split("_")[1];
                    let d2 = config.date[1].split("_")[1];
                    this.value.date1 = moment().subtract(d1, 'day').format("YYYY-MM-DD");
                    this.value.date2 = moment().subtract(d2, 'day').format("YYYY-MM-DD");
                  } else {
                    this.value.date1 = config.date[0]
                    this.value.date2 = config.date[1]
                  }
                }
            }
          }
        }
      }
    },
    /**
     * [getPeriodGoogle prepare the config from the date data]
     *
     *
     * @return  {object}          [return config after being configured]
     */
    getPeriodGoogle() { // prepare the period for the config
      let config = this.date_cnf
      if (this.value.period === "week") {
        config.date = "last_week"
        config.date_model = "preset"
      }
      if (this.value.period === "month") {
        config.date = "last_month"
        config.date_model = "preset"
      }
      if (this.value.period === "year") {
        config.date_model = "preset"
        config.date = "last_year"
      }
      if (this.value.period === "custom") {
        if (this.value.UseSpecificDates) {
          let d1 = this.value.date1
          let d2 = this.value.date2
          if (this.value.moveTimeWindow) {
            let begin_date = moment(d1)
            let end_date = moment(d2)
            let today = moment()
            config.date_model = "preset"
            let n1 = today.diff(begin_date, 'days')
            let n2 = today.diff(end_date, 'days')
            n1 = n1 < 0 ? 0 : n1
            n2 = n2 < 0 ? 0 : n2
            config.date = [`last_${n1}_days`, `last_${n2}_days`]
          } else {
            config.date_model = "custom"
            config.date = [d1, d2]
          }
        } else {
          config.date_model = "preset"
          let n = parseInt(this.value.lastDDays);
          if (!isNaN(n) && n != null && n >= 0) {
            this.value.lastDDays = n
            config.date = `last_${this.value.lastDDays}_days`
          } else {
            this.value.lastDDays = 0
            config.date = "last_0_days"
          }
        }
        config.date_move_time_window = this.value.moveTimeWindow
      } else {
        config.date_move_time_window = true
      }
      this.date_cnf = config
      return config
    },
    emitChange() {
      // let cnf = this.getPeriodGoogle()
      this.$emit("change", {...this.date_cnf, ...this.value, doSelectPeriod: this.doSelectPeriod}); //this.value is to Make the app store values to reuse them if needed
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/theme/config/_colors.scss";

label {
  font-size: 14px;
  text-align: left;
  display: inline-block;
  width: 100%;
  color: var(--default-color-dark-blue);
}

.input-date.preset {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: left;
  border-radius: 25px;
  height: calc(30px + 1.5rem);
  padding: 0.5rem 10px;

  input, select {
    display: inline-block;
    height: 30px;
    min-height: unset;
    margin: 0;
    box-sizing: border-box;
    padding: 0 10px;
    line-height: 30px;
    text-align: center !important;
    background-color: transparent;
    border: none;
  }

  select {
    padding-left: 0;
  }

  & > div.number {
    width: 60px;
  }

  & > div.scale {
    min-width: 80px;
    max-width: 100px;
  }
}
</style>
