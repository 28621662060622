<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    offset-x
    bottom
    max-width="300px"
    content-class="palette-input"
  >
    <template #activator="{ on, attrs }">
      <div
        id="palette-preview"
        v-b-tooltip.hover.top="'Edit custom palette'"
        class="palette-input-container d-inline-block"
        style="width: 120px;height: 40px;position: relative;margin-right: 7px;"
        v-bind="attrs"
        v-on="on"
      >
        <div
          v-b-tooltip.hover.top="'Reset to default'"
          class="text-right"
          style="position: absolute;right: -10px;top: 0; bottom: 0;width: 20px; height: 40px;line-height: 35px;"
          @click="resetPalette"
        >
          <v-icon
            small
          >
            mdi-refresh
          </v-icon>
        </div>
        <div 
          class="d-flex flex-nowrap float-right" 
          style="width: 110px;"
        >
          <span
            v-for="(color, index) in colors.filter((e, i, s) => i < s.length / 3)"
            :key="'color-square-' + index"
            class="palette-color-square"
            :style="'background: ' + color + ';'"
          />
        </div>
        <div
          class="d-flex flex-nowrap float-right"
          style="width: 110px;"
        >
          <span
            v-for="(color, index) in colors.filter((e, i, s) => i >= s.length / 3 && i < (s.length / 3) * 2)"
            :key="'color-square-' + index"
            class="palette-color-square"
            :style="'background: ' + color + ';'"
          />
        </div>
        <div
          class="d-flex flex-nowrap float-right"
          style="width: 110px;"
        >
          <span
            v-for="(color, index) in colors.filter((e, i, s) => i >= (s.length / 3) * 2)"
            :key="'color-square-' + index"
            class="palette-color-square"
            :style="'background: ' + color + ';'"
          />
        </div>
      </div>
    </template>
    <v-card class="palette-input-container text-left">
      <v-card-subtitle
        v-if="expansion == null"
        class="pb-0"
      >
        <strong class="w-100 d-inline-block h5">Custom palette</strong>
        <p class="m-0">
          Choose a category and then click on which color you want to replace with yours
        </p>
      </v-card-subtitle>
      <v-card-text class="colors-preview">
        <v-row class="mt-0">
          <v-expansion-panels
            v-model="expansion"
            class="p-0 shadow-none m-0"
          >
            <v-expansion-panel class="p-0 shadow-none m-0">
              <v-expansion-panel-header>
                Waterfall
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p
                  class="w-100 d-flex flex-nowrap pb-3 pt-3"
                  style="line-height: 12px"
                >
                  <strong class="mr-3">Colors :</strong>
                  <span
                    v-for="(color, index) in colors.filter((e, i) => i < 5)"
                    :id="'color-' + color.replace('#', '')"
                    :key="'color-waterfall-square-' + index"
                    v-b-tooltip.hover.top="'Click to edit'"
                    class="palette-color-square"
                    :style="'background: ' + color + ';'"
                    @click.stop="editColor(index)"
                  />
                </p>
                <v-row>
                  <v-col
                    cols="12"
                    class="p-0"
                  >
                    <div class="w-100 d-flex justify-center pb-3">
                      <div class="mini-waterfall">
                        <div
                          v-b-tooltip.hover.top="'Click to edit'"
                          class="net-start"
                          :style="'background: ' + colors[2] + ';'"
                          @click.stop="editColor(2)"
                        />
                        <div
                          v-b-tooltip.hover.top="'Click to edit'"
                          class="up"
                          :style="'background: ' + colors[3] + ';'"
                          @click.stop="editColor(3)"
                        />
                        <div
                          v-b-tooltip.hover.top="'Click to edit'"
                          class="down expand"
                          :style="'background: ' + colors[0] + ';'"
                          @click.stop="editColor(0)"
                        >
                          <div
                            v-b-tooltip.hover.top="'Click to edit'"
                            class="sub-down up"
                            :style="'background: ' + colors[3] + ';'"
                            @click.stop="editColor(3)"
                          />
                          <div
                            v-b-tooltip.hover.top="'Click to edit'"
                            class="sub-down down"
                            :style="'background: ' + colors[4] + ';'"
                            @click.stop="editColor(4)"
                          />
                        </div>
                        <div
                          v-b-tooltip.hover.top="'Click to edit'"
                          class="net-end"
                          :style="'background: ' + colors[2] + ';'"
                          @click.stop="editColor(2)"
                        />
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    v-if="edit.color !== false"
                    cols="12"
                  >
                    <v-color-picker
                      v-model="colors[edit.color]"
                      class="p-0 d-inline-block w-100 mw-100"
                      dot-size="10"
                      hide-mode-switch
                      mode="hexa"
                      swatches-max-height="124"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="p-0 shadow-none m-0">
              <v-expansion-panel-header>
                Comparison
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <p
                      class="w-100 d-flex flex-wrap pb-3 pt-3"
                      style="line-height: 12px"
                    >
                      <strong class="mr-3">Colors:</strong>
                      <span
                        v-for="(color, index) in colors.filter((e, i) => i >= 5 && i < 7)"
                        :id="'color-' + color.replace('#', '')"
                        :key="'color-comparison-square-' + index"
                        v-b-tooltip.hover.top="'Click to edit'"
                        class="palette-color-square"
                        :style="'background: ' + color + ';'"
                        @click.stop="editColor(index)"
                      />
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex"
                  >
                    <div class="mini-plotly">
                      <div
                        v-b-tooltip.hover.top="'Click to edit'"
                        class="net-start"
                        :style="'background: ' + colors[5] + ';'"
                        @click.stop="editColor(5)"
                      />
                      <div
                        v-b-tooltip.hover.top="'Click to edit'"
                        class="net-end"
                        :style="'background: ' + colors[6] + ';'"
                        @click.stop="editColor(6)"
                      />
                    </div>
                  </v-col>
                  <v-col
                    v-if="edit.color"
                    cols="12"
                  >
                    <v-color-picker
                      v-if="edit.color"
                      v-model="colors[edit.color]"
                      class="p-0 d-inline-block w-100 mw-100"
                      dot-size="10"
                      hide-mode-switch
                      mode="hexa"
                      swatches-max-height="124"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="p-0 shadow-none m-0">
              <v-expansion-panel-header>
                Plots
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <p
                      class="w-100 d-flex flex-wrap pb-3 pt-3"
                      style="line-height: 12px"
                    >
                      <strong class="mr-3 w-100 mb-2">Colors:</strong>
                      <span
                        v-for="(color, index) in colors"
                        :id="'color-' + color.replace('#', '')"
                        :key="'color-comparison-square-' + index"
                        v-b-tooltip.hover.top="'Click to edit'"
                        :class="'palette-color-square ' + (index >= 7 ? '' : 'd-none')"
                        :style="'background: ' + color + ';'"
                        @click.stop="editColor(index)"
                      />
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <div class="mini-plotly multiple">
                      <div
                        v-for="(color, index) in colors"
                        :key="'palette-plot-color-' + index"
                        v-b-tooltip.hover.top="'Click to edit'"
                        :class="'bar ' + (index >= 7 ? '' : 'd-none')"
                        :style="'background: ' + color + ';height: ' + ((((index % 3) + 1) * 10 * 3) - 10) + 'px;margin-top: ' + (100 - (((index % 3) + 1) * 10 * 3)) + 'px;'"
                        @click.stop="editColor(index)"
                      />
                    </div>
                  </v-col>
                  <v-col
                    v-if="edit.color"
                    cols="12"
                  >
                    <v-color-picker
                      v-model="colors[edit.color]"
                      class="p-0 d-inline-block w-100 mw-100"
                      dot-size="10"
                      hide-mode-switch
                      mode="hexa"
                      swatches-max-height="124"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import ColorsManager from "@/components/Structure/Mixins/ColorsManager";

export default {
  mixins: [
    ColorsManager
  ],
  props: {
    isPreferences: {
      type: Boolean,
      default: false
    },
    isOverride: {
      type: [Boolean, Object],
      default: false
    }
  },
  data() {
    return {
      expansion: null,
      open: false,
      colors: [],
      key: 0,
      create: {
        state: false,
        error: {
          state: false,
          message: ''
        }
      },
      temp: '',
      edit: {
        state: false,
        color: false,
        key: 0,
        error: {
          state: false,
          message: ''
        }
      },
      list: ''
    }
  },
  computed: {
    config() {
      if (this.isOverride) {
        if (!this.$store.state.prep.exportConfigurationList[this.isOverride.solution].configuration.blocks[this.isOverride.block].configuration.channels[this.isOverride.channel].configuration.override || !this.$store.state.prep.exportConfigurationList[this.isOverride.solution].configuration.blocks[this.isOverride.block].configuration.channels[this.isOverride.channel].configuration.override.customPalette) {
          return JSON.parse(JSON.stringify(this.$store.state.shared.inputs.customPalette))
        } else {
          return this.$store.state.prep.exportConfigurationList[this.isOverride.solution].configuration.blocks[this.isOverride.block].configuration.channels[this.isOverride.channel].configuration.override.customPalette
        }
      } else {
        return this.$store.state.shared.inputs.customPalette
      }
    }
  },
  watch: {
    colors: {
      deep: true,
      handler() {
        this.input(this.colors)
      }
    },
    temp(v) {
      this.$set(this.colors, this.edit.color, v)
    }
  },
  mounted() {
    if (this.config.value != null) {
      this.colors = this.config.value;
    }
  },
  methods: {
    resetPalette() {
      this.$set(this, 'colors', [...this.base.default])
    },
    editColor(id) {
      this.edit.color = id;
    },
    refresh() {
      this.key++;
    },
    /**
     * When input updates update his value on the store
     * @param value
     */
    input(value) {
      if (this.isOverride) {
        let obj;
        if (!this.$store.state.prep.exportConfigurationList[this.isOverride.solution].configuration.blocks[this.isOverride.block].configuration.channels[this.isOverride.channel].configuration.override) {
          obj = {}
          this.$set(this.$store.state.prep.exportConfigurationList[this.isOverride.solution].configuration.blocks[this.isOverride.block].configuration.channels[this.isOverride.channel].configuration, 'override', obj)
        } else {
          obj = this.$store.state.prep.exportConfigurationList[this.isOverride.solution].configuration.blocks[this.isOverride.block].configuration.channels[this.isOverride.channel].configuration.override
        }
        this.$set(obj, 'customPalette', {...this.config, value: value})
      } else {
        if (this.isPreferences) {
          this.$store.dispatch('user/updateInputValue', {id: this.config.target != null ? this.config.target : this.id, value: value})
        } else {
          this.$store.dispatch('shared/updateInputValue', {id: this.config.target != null ? this.config.target : this.id, value: value})
        }
      }
    }
  }
}
</script>
<style lang="scss">
body > .tooltip.b-tooltip {
  z-index: 99999 !important;
}
.palette-input-container {
  & .palette-color-square {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 3px;
    margin: 2px 5px 0 0;
    box-sizing: border-box !important;
    cursor: pointer;
  }
  .mini-plotly {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 100px;
    margin: 0 auto;
    &::before, &::after {
      content: "";
      position: absolute;
    }
    &::before {
      left: 0;
      top: 10px;
      bottom: 5px;
      width: 1px;
      background: rgba(0,0,0,0.1);
    }
    &::after {
      left: 0;
      right: 5px;
      bottom: 5px;
      height: 1px;
      background: rgba(0,0,0,0.1);
    }
    &.multiple {
      padding-left: 5px;
      display: flex;
      flex-wrap: nowrap;
      .bar {
        display: inline-block;
        width: 10px;
        margin-right: 5px;
      }
    }
    & > * {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      cursor: pointer;
    }
    .net-start {
      position: absolute;
      top: 10px;
      left: 25%;
      width: 15%;
      height: 80px;
    }
    .net-end {
      position: absolute;
      left: 50%;
      width: 15%;
      top: 50px;
      height: 40px;
    }
  }
  .mini-waterfall {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 100px;
    & > * {
      position: absolute;
      display: inline-block;
      width: 20px;
      cursor: pointer;
    }
    .net-start {
      top: 40px;
      left: 5px;
      height: 60px;
    }
    .net-end {
      top: 23px;
      left: 129px;
      height: 76px;
    }
    .up {
      left: 30px;
      top: 10px;
      height: 30px;
    }
    .down {
      border-radius: 5px;
      left: 60px;
      top: 9px;
      background: transparent;
      border: 1px solid rgba(0,0,0,0.1);
      width: 59px;
      height: 36px;
      & .sub-down {
         &.up {
           z-index: 1;
           position: absolute;
           top: 3px;
           height: 20px;
           left: 3px;
           width: 20px;
           border-radius: 0;
           cursor: pointer;
         }
        &.down {
          z-index: 1;
          position: absolute;
          top: 3px;
          height: 30px;
          left: 31px;
          width: 20px;
          border-radius: 0;
          border: unset;
          cursor: pointer;
        }
      }
    }
  }
  & .color-group {
    & span:not(.chip):empty, & td:empty {
      display: none;
    }
    & strong, & th {
      font-size: 13px;
    }
    .chip {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: inline-block;
      & .v-color-picker__controls .v-color-picker__edit {
        margin: 0;
        & .v-color-picker__controls {
          padding: 5px 10px;
        }
      }
    }
  }
  & .color {
    height: 30px;
    line-height: 30px;
    padding: 5px 10px 5px 25px;
    position: relative;
    margin: 10px 10px 0 0;
    &::before {
      border: 1px solid currentColor;
      border-radius: 15px;
      position: absolute;
      left: 0;
      right: 0;
      opacity: 0.5;
      top: 0;
      bottom: 0;
      content: "";
    }
    &.temp {
      padding: 0;
      width: 30px !important;
      height: 30px !important;
      display: inline-block;
      line-height: 30px;
      text-align: center;
      & i {
        line-height: 30px;
      }
      &::before {
        border-color: black;
        width: 30px !important;
        height: 30px !important;
        border-radius: 50%;
      }
    }
    .preview {
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
      display: inline-block;
      border-radius: 50%;
      background: currentColor;
    }
  }
}
.palette-input {
  transform: translateY(-50%) !important;
}
</style>