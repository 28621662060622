var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datama-calculated-fields d-flex flex-column"},[_vm._l((_vm.value),function(item,index){return _c('div',{key:'calculated_field_' + index,staticClass:"datama-calculated-field"},[_c('div',{staticClass:"datama-calculated-field-header"},[_c('v-text-field',{staticClass:"float-left",attrs:{"type":"text","label":item.edit ? 'Column name' : '',"hide-details":item.edit ? 'auto' : null,"readonly":!item.edit},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('span',{staticClass:"float-right"},[(!item.edit)?_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Remove'),expression:"'Remove'",modifiers:{"hover":true,"top":true}}],on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.removeElement(index); })($event)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(!item.edit)?_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Edit'),expression:"'Edit'",modifiers:{"hover":true,"top":true}},{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],on:{"click":function($event){$event.stopPropagation();item.edit = !item.edit}}},[_vm._v(" mdi-pencil ")]):_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Close edition'),expression:"'Close edition'",modifiers:{"hover":true,"top":true}},{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"float-right",on:{"click":function($event){$event.stopPropagation();item.edit = !item.edit}}},[_vm._v(" mdi-check ")])],1)],1),_c('div',{class:'datama-calculated-field-form ' + (item.edit ? 'open' : ''),staticStyle:{"border":"none !important"}},[_c('AceEditor',{staticClass:"float-left",staticStyle:{"line-height":"24px","margin-top":"3px"},attrs:{"theme":"chrome","width":"100%","height":"100%","lang":"matlab","options":{
          autoScrollEditorIntoView: true,
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          fontSize: 16,
          lineHeight: 40,
          highlightActiveLine: false,
          showLineNumbers: false,
          tabSize: 2,
          showPrintMargin: false,
          showGutter: false,
          wrap: false,
          maxLines: 1,
          minLines: 1,
          readonly: !item.edit
        }},on:{"init":_vm.editorInit},model:{value:(item.formula),callback:function ($$v) {_vm.$set(item, "formula", $$v)},expression:"item.formula"}})],1)])}),(_vm.value.length)?_c('v-divider',{staticClass:"mt-2 mb-3"}):_vm._e(),(!_vm.editionState)?_c('button',{staticClass:"datama-calculated-field-add",on:{"click":function($event){$event.stopPropagation();_vm.editionState = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus ")]),_vm._v(" New calculated field ")],1):_c('div',{staticClass:"datama-calculated-field-form"},[_c('div',{staticClass:"mb-1"},[_c('v-text-field',{attrs:{"type":"text","label":"Column name","readonly":!_vm.edition.edit},model:{value:(_vm.edition.name),callback:function ($$v) {_vm.$set(_vm.edition, "name", $$v)},expression:"edition.name"}})],1),_c('div',{staticClass:"mb-1",staticStyle:{"display":"flex","flex-wrap":"nowrap","border":"1px solid rgba(0,0,0,0.1)","padding":"5px 10px","height":"40px"}},[_c('AceEditor',{staticStyle:{"line-height":"24px","margin-top":"3px"},attrs:{"theme":"chrome","width":"100%","height":"100%","lang":"matlab","options":{
          autoScrollEditorIntoView: true,
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          fontSize: 16,
          lineHeight: 40,
          highlightActiveLine: false,
          showLineNumbers: false,
          tabSize: 2,
          showPrintMargin: false,
          showGutter: false,
          wrap: false,
          maxLines: 1,
          minLines: 1
        },"readonly":!_vm.edition.edit},on:{"init":_vm.editorInit},model:{value:(_vm.edition.formula),callback:function ($$v) {_vm.$set(_vm.edition, "formula", $$v)},expression:"edition.formula"}})],1),_c('div',{staticClass:"d-flex justify-end mb-2"},[_c('v-btn',{staticClass:"mr-2 transparent shadow-none",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.editionState = false}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"bg-primary",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.newCalculatedField($event)}}},[_vm._v(" Add ")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }