import { render, staticRenderFns } from "./SolutionSettingsList.vue?vue&type=template&id=297bc61c&"
import script from "./SolutionSettingsList.vue?vue&type=script&lang=js&"
export * from "./SolutionSettingsList.vue?vue&type=script&lang=js&"
import style0 from "./SolutionSettingsList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports