<template>
  <div>
    <DataMaMetricRelation
      :minify="minify"
      @error="e => error = e"
    />
    <p
      v-if="error"
      class="text-left mt-5"
    >
      <v-icon>mdi-information-outline</v-icon> You have some issue in your metrics relation,
      <a
        href="#"
        @click.stop="openDialog"
      >
        open details to see them
      </a>.
    </p>
  </div>
</template>
<script>
import DataMaMetricRelation from "./DataMaMetricRelation.vue";
export default {
  name: 'DataMaMetricRelationOverlay',
  components: {
    DataMaMetricRelation
  },
  props: {
    minify: {
      type: Boolean,
      default() {
        return false;
      }
    },
    live: {
      type: Boolean,
      default() {
        return false;
      }
    },
    header: {
      type: Boolean,
      default() {
        return false;
      }
    },
    button: {
      type: Boolean,
      default() {
        return false;
      }
    },
    steps: {
      type: Array,
      default() {
        return this.$store.state.shared.steps
      }
    },
    dimensions: {
      type: Array,
      default() {
        return this.$store.state.shared.dimensions
      }
    },
    metrics: {
      type: Array,
      default() {
        return this.$store.state.shared.metrics
      }
    },
    total: {
      type: Object,
      default() {
        return this.$store.state.shared.inputs.step_total
      }
    }
  },
  data() {
    return {
      error: false
    }
  },
  computed: {
    open: {
      get() {
        return this.$store.state.shared.core.showMetricRelationDialog
      },
      set(v) {
        this.$store.dispatch('shared/toggleDialog', {name: 'metricRelation', value: v})
      }
    }
  },
  mounted() {
    if (this.live) {
      this.edition = this.steps
    } else {
      this.edition = JSON.parse(JSON.stringify(this.steps))
    }
  },
  methods: {
    openDialog(e) {
      e.preventDefault();
      this.$set(this, 'open', true)
      this.$emit('close')
    },
  }
}
</script>