var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"org-manage-members"},[_c('DatamaTitle',{attrs:{"title":"Manage *Members*"}}),_c('DatamaSubtitle',{attrs:{"subtitle":"Manage workspace members and their permissions"}}),_c('div',{staticClass:"d-flex justify-end"},[(_vm.formatedMembers && _vm.formatedMembers.length && _vm.organization && _vm.membership && _vm.membership.is_admin === true)?_c('InviteMemberModal'):_vm._e()],1),(_vm.organization && _vm.membership && _vm.membership.is_admin)?_c('div',{staticClass:"shadow-none p-0"},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-text-field',{staticClass:"search-bar m-0 p-0 mb-2 ml-0 mr-auto",staticStyle:{"max-width":"250px"},attrs:{"label":"Search user","hide-details":"","outlined":"","dense":"","rounded":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.formatedMembers && _vm.formatedMembers.length)?_c('div',{staticClass:"ml-auto mr-0",staticStyle:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.formatedMembers.length)+" / "+_vm._s(_vm.organization.shared_workbooks_limit)+" members ")]):_c('v-skeleton-loader',{staticClass:"ml-auto mr-0 mt-1",staticStyle:{"align-self":"center"},attrs:{"type":"text","width":"100px"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredOrgMembers,"item-key":"id","search":_vm.search,"loading":_vm.areOrgMembersLoading,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.role)+" ")]}},{key:"item.shared_workbooks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shared_workbooks)+" ")]}},{key:"item.extension_workbooks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.extension_workbooks)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'active')?_c('span',{staticClass:"green--text"},[_vm._v("Active")]):(item.status === 'pending')?_c('span',{staticClass:"orange--text"},[_vm._v("Pending")]):_c('span',{staticClass:"red--text"},[_vm._v("Inactive")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"button-container"},[_c('UpdateMemberModal',{attrs:{"member":_vm.getMemberById(item.id)}}),_c('DeleteMemberModal',{attrs:{"member":item}})],1)]}}],null,true)})],1):(_vm.organization && _vm.membership && _vm.membership.is_admin === false)?_c('div',[_c('h3',{staticStyle:{"text-align":"center","color":"#F8663C","margin-top":"20%"}},[_vm._v(" Permissions missing in "+_vm._s(_vm.organization.name)+" ")])]):_c('div',[_c('InfoMessage',{staticClass:"mb-4",attrs:{"styles":"color:grey;display:block;line-height: 20px;font-size: 14px;","icon":"mdi mdi-information-outline","message":"You don't belong to any organization yet. Please ask to your administrator to join an existing organisation."}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }