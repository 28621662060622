<script>
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
import {solution} from "@/config/solution";

export default {
  name: 'SolutionSettingsAbstract',
  mixins: [DataMaLogicAbstract],
  props: {
    groups: {
      type: [Array, Object],
      default() {
        return null
      }
    },
    context: {
      type: Object,
      default() {
        return null
      }
    },
    isPreferences: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isOverride: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      panel: 0
    }
  },
  computed: {
    solutionSettingsGroup() {
      return solution[this.slug].settings
    },
    settingsGroup() {
      if (this.groups) {
        return this.groups
      }
      return [this.solutionSettingsGroup, ...this.globalSettingsGroup]
    },
    current() {
      return this.settingsGroup[this.panel];
    }
  }
}
</script>