<template>
  <div class="org-credentials">
    <DatamaTitle title="Shared *Credentials*" />
    <DatamaSubtitle
      v-if="isOrgAdmin"
      subtitle="Manage and access shared credentials."
    />
    <DatamaSubtitle
      v-else
      subtitle="Access shared credentials."
    />
    <CredentialCardsList />
  </div>
</template>

<script>

import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
import DatamaTitle from "@/components/Home/Structure/Misc/DatamaTitle.vue";
import DatamaSubtitle from "@/components/Home/Structure/Misc/DatamaSubtitle.vue";
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import CredentialCardsList from "@/components/Home/Credentials/CredentialCardsList.vue";

export default {
  name: 'OrgCredentials',
  components: {CredentialCardsList, DatamaSubtitle, DatamaTitle},
  mixins: [
    DataMaLogicAbstract,
    OrgAbstract
  ],
}
</script>