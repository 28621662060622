<template>
  <div class="progress-box">
    <div class="header">
      <h6>
        {{ title }}
      </h6>
      <h6>
        {{ used }}
      </h6>
    </div>
    <v-progress-linear
      :color="color"
      rounded
      :value="progress"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    used: {
      type: String,
      required: true
    },
    progress: {
      type: Number,
      required: true
    }
  },
  computed: {
    color(){
      return this.progress > 60 ? ( 60 <= this.progress && this.progress < 100 ? "orange" : "red") : "secondary"
    }
  }
}
</script>

<style scoped lang="scss">
.progress-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  min-height: 69px;
  padding: 8px 0px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.used {
  font-size: 16px;
  font-weight: bold;
}
</style>
