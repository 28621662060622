<template>
  <div>
    <DatamaTitle title="Administration *Panel verification*" />
    <DatamaSubtitle
      subtitle="Please verify yourself before entering this area."
    />
    <v-btn
      class="bg-primary"
      block
      :loading="btnLoading"
      @click="generateOTPCode"
    >
      Send a code
    </v-btn>
    <div class="mt-6 ms-2 d-flex flex-nowrap">
      <v-otp-input
        v-model="otp"
        variant="underlined"
        :disabled="loading"
        hide-details
        length="6"
        type="number"
        @finish="onFinish"
      />
      <v-btn
        class="bg-primary ml-4 align-self-center"
        style="max-height: unset;height:56px"
        :loading="loading"
        @click="verifyAdminOtp"
      >
        Ok
      </v-btn>
    </div>
  </div>
</template>


<script>
import DatamaSubtitle from "@/components/Home/Structure/Misc/DatamaSubtitle.vue";
import DatamaTitle from "@/components/Home/Structure/Misc/DatamaTitle.vue";

export default {
  name: 'OtpValidator',
  components: {
    DatamaTitle,
    DatamaSubtitle
  },
  mixins: [],
  data(){
    return {
      otp:"",
      loading:false,
      btnLoading:false
    }
  },
  methods: {
    onFinish(){
      this.verifyAdminOtp()
    },
    generateOTPCode(){
      this.btnLoading = true
      this.$store.dispatch("admin/generateOTPCode").then((resp) => {
        this.btnLoading = false
      }).catch((e) => {
        this.$toast.error("Unable to generate OTP code", { timeout: 3000 })
        console.error(e)
        this.btnLoading = false
      })
    },
    verifyAdminOtp(){
      this.loading = true
      this.$store.dispatch("admin/verifyAdminOtp", {
        data: {
          code: this.otp
        }
      }).then((resp) => {
        let data = resp.data
        this.loading = false
        let session = data["session_id"]
        let session_duration = data["expire_in"]
        let now = new Date()
        this.$cookies.set("datama-admin-secure-session", session, now.setSeconds(now.getSeconds() + session_duration))
        this.$emit('update')
      }).catch((err) => {
        console.error(err)
        this.isAdminAuthorized = false
        this.loading = false
      })
    }
  }
}

</script>