<script>
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";

export default {
  name: 'DataMaEventsAbstract',
  mixins: [DataMaLogicAbstract],
  data() {
    return {
      handlers: {
        keys: {
          shift: false,
          control: false,
          alt: false
        },
        watch: {}
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
    this.registerKeysHandler(['ctrl','s'], this.save, 'solutionSave')
  },
  beforeDestroy() {
    this.unregisterKeysHandler(['ctrl', 's'])
  },
  methods: {
    save() {
      if (!this.anonymous) {
        return this.$emit('save')
      }
    },
    handleKeyDown(e) {
      if (e.key && e.key.toLowerCase() === 'control') {
        this.handlers.keys.control = true;
      } else if (e.key && e.key.toLowerCase() === 'shift') {
        this.handlers.keys.shift = true;
      } else if (e.key && e.key.toLowerCase() === 'alt') {
        this.handlers.keys.alt = true;
      } else {
        if (e.key) {
          this.checkCombination(e)
        }
      }
    },
    checkCombination(event) {
      let meta = [];
      if (this.handlers.keys.control) {
        meta.push('ctrl')
      } else if (this.handlers.keys.shift) {
        meta.push('shift')
      } else if (this.handlers.keys.alt) {
        meta.push('alt')
      }
      let key = event.key;
      let code = [...meta, key].join('+');
      if (this.handlers.watch[code]) {
        let executable = Object.keys(this.handlers.watch[[...meta, key].join('+')]);
        let i = 0;
        while (i < executable.length) {
          if (typeof this.handlers.watch[code][executable[i]] === 'function') {
            if (this.developer) {
              console.info("%cExecute handler of [" + code + "] : " + executable[i], 'background: #222; color: #bada55')
            }
            this.handlers.watch[code][executable[i]](event);
          }
          i++;
        }
      }
    },
    handleKeyUp(e) {
      if (e.key && e.key.toLowerCase() === 'control') {
        this.handlers.keys.control = false;
      } else if (e.key && e.key.toLowerCase() === 'shift') {
        this.handlers.keys.shift = false;
      } else if (e.key && e.key.toLowerCase() === 'alt') {
        this.handlers.keys.alt = false;
      }
    },
    registerKeysHandler(keys=[], action=null, id=null) {
      if (action) {
        let code = keys.join('+')
        if (!this.handlers.watch[code]) {
          this.handlers.watch[code] = {}
        }
        if (!id) {
          id = Object.keys(this.handlers.watch[code]).length
        }
        if (this.developer) {
          console.info("%cRegister new handler for [" + code + "] : " + id, 'background: #222; color: #bada55')
        }
        this.handlers.watch[code][id] = action;
      }
    },
    unregisterKeysHandler(keys=[], id=null) {
      let code = keys.join('+')
      if (this.handlers.watch[code]) {
        if (id && this.handlers.watch[code][id]) {
          if (this.developer) {
            console.info("%cRemove '" + id + "' handler for [" + code + "] : ", 'background: #222; color: #bada55')
          }
          delete this.handlers.watch[code][id]
        } else if (!id) {
          if (this.developer) {
            console.info("%cRemove handler for [" + code + "] : ", 'background: #222; color: #bada55')
          }
          delete this.handlers.watch[code]
        }
      }
    }
  }
}
</script>