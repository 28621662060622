/**
 * [searchBlock Search through all flow for a specific blockId]
 * @param  {integer} blockId [The id to look for]
 * @param  {object} store   [the store]
 * @return {object}         [block id and flow id]
 */
export default function searchBlock(blockId, store) {
    for (var k = 0; k < store.flowConfigurationList.length; k++) {
        const flow = store.flowConfigurationList[k]
        const block = flow.getBlock(blockId)
        const i = flow.searchBlock(blockId)
        if (block !== null) {
            return ({
                flowid: k,
                block: block,
                position: i
            })
        }
    }
    return null
}
