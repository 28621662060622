// Put all states you normaly use in a initialState function this way we can Reset state
// at any given moment without removing newly added or immutable states such as VAPI urls 
// and without mutating our initialState
import Vue from "vue";
import {developer_mode} from "@/utils/developer_mode";

function asideInitialState(){
    return {
        aside_size: "400px",
        previous_aside_size: "400px",
        displaySnackBar: false,
        messageSnackBar: "",
        asideClassExpand: "aside-open",
        spinnerClass: "",
        spinnerClassAutoSave: "",
        errorTipClassAutoSave: "",
        errorTipClass: "",
        spinners: [],
        slideSolutionHeaderState: false
    }
}
const state = asideInitialState()
const actions = {
    setAsideSize({commit}, size) {
        commit("SET_ASIDE_SIZE", size)
    },
    slideSolutionHeader({commit}, payload) {
        commit("SLIDE_SOLUTION_HEADER", payload)
    },
    setAsidePreviousSize({commit}) {
        commit("SET_ASIDE_PREVIOUS_SIZE")
    },
    resetAsideSize({commit}) {
        commit("SET_ASIDE_SIZE", "400px")
    },
    displaySnackBar({commit}, payload) {
        commit("DISPLAY_SNACKBAR", payload)
    },
    /**
     * For moment here, but this is use to toggle sample preview (and disable draggable when close)
     * @param state
     * @param payload
     */
    toggleAside({commit}, payload) {
        commit("TOGGLE_ASIDE", {class: payload.class})
    },
    showSpinner({commit}, payload) {
        commit("SHOW_SPINNER", payload)
    },
    hideSpinner({commit}, payload) {
        commit("HIDE_SPINNER", payload)
    },
    errorSpinner({commit}, payload) {
        commit("ERROR_SPINNER", payload)
    },
    resetSpinner({commit}, payload) {
        commit("RESET_SPINNER", payload)
    }
}

const mutations = {
    SET_ASIDE_SIZE(state, size) {
        state.previous_aside_size = state.aside_size
        state.aside_size = size
    },
    SET_ASIDE_PREVIOUS_SIZE(state) {
        state.aside_size = state.previous_aside_size
    },
    SLIDE_SOLUTION_HEADER(state, slide) {
        state.slideSolutionHeaderState = slide.state
    },
    DISPLAY_SNACKBAR(state, payload) {
        if (payload === false) {
            state.displaySnackBar = false
            state.messageSnackBar = ""
        } else {
            state.displaySnackBar = true
            state.messageSnackBar = payload
        }
    },
    SHOW_SPINNER(state, spinner = false) {
        if (spinner) {
            let id = state.spinners.map(s => s.id).indexOf(spinner.id);
            if (id === -1) {
                state.spinners.push({...spinner, started: Date.now(), error: false, state: true})
                if (developer_mode()) {
                    console.info("%c--> " + spinner.id, 'background: #222; color: limegreen')
                }
            } else {
                Vue.set(state.spinners[id], 'state', true)
                Vue.set(state.spinners[id], 'error', false)
                Vue.set(state.spinners[id], 'started', Date.now())
                if (developer_mode()) {
                    console.info("%c--> " + spinner.id + " (multiple)", 'background: #222; color: darkkhaki')
                }
            }
        }
    },
    HIDE_SPINNER(state, spinner = false) {
        if (spinner) {
            let id = state.spinners.map(s => s.id).indexOf(spinner.id);
            if (id === -1) {
                if (developer_mode()) {
                    console.warn("%c<-- " + spinner.id + " (non existing)", 'background: #222; color: steelblue')
                }
            } else {
                Vue.set(state.spinners[id], 'state', false)
                Vue.set(state.spinners[id], 'error', false)
                Vue.set(state.spinners[id], 'ended', Date.now())
                if (developer_mode()) {
                    console.info("%c<-- " + spinner.id + " (" + (state.spinners[id].ended - state.spinners[id].started) + "ms)", 'background: #222; color: deepskyblue')
                }
                Vue.delete(state.spinners, id)
            }

        }
    },
    ERROR_SPINNER(state, spinner) {
        if (spinner) {
            let id = state.spinners.map(s => s.id).indexOf(spinner.id);
            if (id !== -1) {
                Vue.set(state.spinners[id], 'error', true);
                if (developer_mode()) {
                    console.error("%c<!> " + spinner.id + " (multiple)", 'background: #222; color: yellow')
                }
            } else {
                if (developer_mode()) {
                    console.info("%c<!> " + spinner.id, 'background: #222; color: gold')
                }
                state.spinners.push({...spinner, started: Date.now(), error: true})
            }
        }
    },
    RESET_SPINNER(state, {id=false, namespace= false}) {
        if (id) {
            state.spinners = state.spinners.filter(s => Array.isArray(id) ? id.indexOf(s.id) === -1 : s.id !== id)
            if (developer_mode()) {
                console.info("%c<-- " + (Array.isArray(id) ? id.join(', ') : id) + " (stopped)", 'background: #222; color: deepskyblue')
            }
        } else if (namespace) {
            state.spinners = state.spinners.filter(s => Array.isArray(namespace) ? namespace.indexOf(s.id.split('/')[0]) === -1 : s.id.split('/')[0] !== namespace)
            if (developer_mode()) {
                console.info("%c<-- " + (Array.isArray(namespace) ? namespace.join('/*, ') : namespace) + "/* (stopped)", 'background: #222; color: deepskyblue')
            }
        }
    },
    TOGGLE_ASIDE(state, payload) {
        state.asideClassExpand = payload.class
    }
}
/*
    locks are specific states in the store that are used to lock treatements based on a suposed state flow
    ( similar to an Automata flowchart ) you can see a description of that flow in each different lock state.
    this part might be refactored if we're going to use a state lock manager such as XState 
    */
const locks ={
    state:{
        /*this is a state that locks block selection; block deselection; Toogling Aside component hand 
            * it has 3 possible values:
            *    - 0-> FREE: no files have been added
            *    - 1-> UNCOMMITED: file has been added but not commited -> asks if you're sure you want to cancel the loading
            *    - 2-> READY_TO_UNLOAD: user wants to cancel the loading
            * 0 -> add file -> 1 -> loadfile -> 0
            * 0 -> lockedEvent(success)
            * 1 -> lockedEvent(block) && show tooltipYesNo -> conditionMet -> 2 
            *                                              -> choose Yes -> 0
            *                                              -> choose No -> 1
            *                                              -> lockedEvent(sucess) -> 0
            * 
            * add file is in : DragDrop->mounted
            * loadfile : DragDrop->submitFiles
            * lockedEvents : Prep -> SelectBlock , DeselectBlock ; Aside -> ToogleAside
            * //! you can find the flow handler in /src/utils/lock.js -> ASIDE_UNCOMMITED_CHANGE_FLOW
        */
        ASIDE_UNCOMMITED_CHANGE:"FREE", //? and enum where the values are FREE; UNCOMMITED; READY_TO_UNLOAD
        SHOW_ASIDE_UNCOMMITED_CHANGE_TOOLTIP:false,
        ASIDE_UNCOMMITED_CHANGE_LOCKEDEVENT_COUNT:0,
    },
    mutations:{
        ASIDE_UNCOMMITED_CHANGE(state,val){
            state.ASIDE_UNCOMMITED_CHANGE = val;
        },
        SHOW_ASIDE_UNCOMMITED_CHANGE_TOOLTIP(state,val){
            state.SHOW_ASIDE_UNCOMMITED_CHANGE_TOOLTIP = val;
        },
        INCREMENT_ASIDE_UNCOMMITED_CHANGE_LOCKEDEVENT_COUNT(state){
            state.ASIDE_UNCOMMITED_CHANGE_LOCKEDEVENT_COUNT += 1;
        },
        RESET_ASIDE_UNCOMMITED_CHANGE_LOCKEDEVENT_COUNT(state){
            state.ASIDE_UNCOMMITED_CHANGE_LOCKEDEVENT_COUNT = 0;
        },
    },
    actions:{
        updateAsideUncommitedChange({commit},val){
            commit("ASIDE_UNCOMMITED_CHANGE",val)
        },
        showAsideUncommitedChangeTooltip({commit},val){
            commit("SHOW_ASIDE_UNCOMMITED_CHANGE_TOOLTIP",val)
        },
        incrementAsideUncommitedChangeLockedEventCount({commit}){
            commit("INCREMENT_ASIDE_UNCOMMITED_CHANGE_LOCKEDEVENT_COUNT");
        },
        resetAsideUncommitedChangeLockedEventCount({commit}){
            commit("RESET_ASIDE_UNCOMMITED_CHANGE_LOCKEDEVENT_COUNT");
        },
    },
}
const getters = {}
// export default sample;
//TODO add locks here
export default {
    namespaced: true,
    name: 'aside',
    state: {...state,...locks.state},
    mutations: {
        ...mutations,
        ...locks.mutations
    },
    actions: {...actions,...locks.actions},
    getters: {...getters}
}

