<script>
import {block_list_internal} from "@/config";

export default {
  name: 'OrgAbstract',
  props: {},
  data() {
    return {
      frontBackMap: {
        "source-google-sheets": "google-sheet",
        "source-sheets": "google-sheet",
        "source-google-analytics": "google-analytics",
        "source-google-analytics4": "google-analytics4",
        "google-analytics4": "google-analytics4",
        "source-google-bigquery": "google-bigquery",
        "source-snowflake": "snowflake",
        "source-amazon-redshift": "amazon-redshift",
        "facebook-analytics": "facebook-analytics",
        "export-slack": "export-slack",
        "export-teams": "export-teams",
        "source-sql-connector": "sql",
      },
      backend: true
    }
  },
  computed: {
    isOrgLoading() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/getOrganization')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/getOrganization')[0].state === true
    },
    areOrgsLoading() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/getOrganizations')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/getOrganizations')[0].state === true
    },
    isOrgCreated() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/createOrganization')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/createOrganization')[0].state === true
    },
    areOrgMembersLoading() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/getMembers')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/getMembers')[0].state === true
    },
    isOrgUsageLoading() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/getOrgUsage')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/getOrgUsage')[0].state === true
    },
    isOrgAdmin() {
      if (this.$store.state.organization && this.membership !== null && this.$store.state.organization.org !== null) {
        return this.membership.is_admin && this.$store.state.organization.org.oid !== null
      }
      return false;
    },
    membership() {
      return this.$store.state.organization.membership;
    },
    orgMembers() {
      return this.$store.state.organization.org_members;
    },
    sharedFlow() {
      return this.$store.state.organization.shared_flow;
    },
    orgUsage() {
      return this.$store.state.organization.org_usage;
    },
    organizations() {
      return this.$store.state.organization.organizations;
    },
    credentials() {
      return this.$store.state.organization.tokens
    },
    shared_credentials() {
      return this.$store.state.organization.shared_credentials || []
    },
    hasOrganization() {
      return !(typeof this.organization === "function") && (typeof this.organization?.name === "string")
    },
    formatedCredentials() {
      let result = [];
      this.credentials.forEach(credential => {
        let matchingSharedCredential = this.shared_credentials.find(shared_credential => shared_credential.token_id === credential.token_id);
        if (matchingSharedCredential) {
          credential.shared_credential = matchingSharedCredential;
          credential.shared = true
        } else {
          credential.shared = false
        }
        result.push(credential);
      });
      return result;
    },
    formatedCredentialsWithSource() {
      return block_list_internal.filter(item => item.type && item.has_token).map(item => {
        const backendType = this.frontBackMap[item.type];
        let matchingObject;
        if (backendType !== "sql") {
          matchingObject = this.formatedCredentials.find(obj => obj.service === backendType);
        } else {
          matchingObject = this.formatedCredentials.find(obj => obj.service.startsWith('sql_'));
        }
        if (matchingObject) {
          return {...item, ...matchingObject};
        }
        return item;
      }).sort((a, b) => {
        if (a.uid === undefined && b.uid !== undefined) return 1;
        if (a.uid !== undefined && b.uid === undefined) return -1;
        return 0;
      });
    }
  },
  methods: {
    async checkSharedConnection(service) {
      await this.$store.dispatch("organization/getSharedCredentials")
      if (service !== "sql-connector") {
        return this.shared_credentials.find(shared_credential => shared_credential.token.service === service);
      } else {
        return this.shared_credentials.find(shared_credential => shared_credential.token.service.startsWith('sql_'));
      }
    },
    getSharedCredentials() {
      this.sharedCredentialBusy = true
      return this.$store.dispatch("organization/getSharedCredentials")
          .then(response => {
            this.sharedCredentialBusy = false
          })
          .catch(error => {
            this.sharedCredentialBusy = false
            this.registerToast(this.$toast.error('Failed to retrieve shared credentials. Please retry.', {timeout: 10000}))
          })
    },
    getAllUserCredentials() {
      this.sharedCredentialBusy = true
      return this.$store.dispatch("organization/getAllUserTokens")
          .then(response => {
            this.sharedCredentialBusy = false
          })
          .catch(error => {
            this.sharedCredentialBusy = false
            this.registerToast(this.$toast.error('Failed to retrieve credentials. Please retry.', {timeout: 10000}))
          })
    },
  },
}
</script>