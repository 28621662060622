<template>
  <div
    ref="context"
    class="context-menu"
    :style="'left:' + x + 'px;top: ' + y + 'px;'"
    v-html="html"
  />
</template>
<script>
import {DataWaterfall} from "@/utils/waterfall";
import DataMaLogicAbstract from "../../../../../Structure/Mixins/DataMaLogicAbstract.vue";

export default {
  name: 'WaterfallContextMenu',
  mixins: [
    DataMaLogicAbstract
  ],
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    identifier: {
      type: String,
      default: ''
    },
    bar: {
      type: Object,
      default() {
        return null
      }
    },
    mode: {
      type: String,
      default() {
        return 'waterfall';
      }
    }
  },
  data() {
    return {
      actionable: true,
      elements: {
        waterfall: [
          {
            id: 'values-to-pts',
            name: () => {
              let mode = this.$parent.configuration.value
              let text;
              if (mode === 'value') {
                text = 'Display impact in points'
              } else {
                text = 'Display impact in volume'
              }
              return text;
            },
            if: () => this.bar.Type === 'net' && (!this.$parent.drill_down || this.$parent.drill_down === 'Steps then Dimensions'),
            click: (e) => {
              e.stopPropagation()
              e.preventDefault()
              let configuration = JSON.parse(JSON.stringify(this.$parent.configuration))
              if (!configuration.active) configuration.active = true;
              if (configuration.value === 'value') {
                configuration.value = 'point'
              } else {
                configuration.value = 'value'
              }
              this.$emit('customization', configuration)
            },
            icon: 'mdi mdi-percent'
          },
          {
            id: 'zoom',
            if: () => !(this.bar.Type === 'net' || this.bar.Type === 'net_OoS'),
            name: () => {
              let alreadyExpand = this.$parent.dataGetter.getChild(this.bar, this.$parent.waterfall_configuration.data).length > 0
              let canHaveMoreChild = this.$parent.dataGetter.getChild(this.bar, this.$parent.dataGetter.__data, false, this.$parent.waterfall_configuration.initialFilter).filter(e => this.$parent.waterfall_configuration.data.indexOf(e) < 0)
              let text;
              if (!alreadyExpand && canHaveMoreChild.length > 0) {
                text = "Zoom in"
              } else {
                text = "Zoom out"
              }
              return text;
            },
            click: () => {
              let alreadyExpand = this.$parent.dataGetter.getChild(this.bar, this.$parent.waterfall_configuration.data).length > 0
              let canHaveMoreChild = this.$parent.dataGetter.getChild(this.bar, this.$parent.dataGetter.__data, false, this.$parent.waterfall_configuration.initialFilter).filter(e => this.$parent.waterfall_configuration.data.indexOf(e) < 0)
              if (!alreadyExpand && canHaveMoreChild.length > 0) {
                this.$parent.click(false, this.bar)
              } else {
                let childZoomable = this.$parent.__getZoomableFromElement(this.bar);
                let childFilter = {
                  zoom: childZoomable,
                  filter: this.$parent.waterfall_configuration.initialFilter
                }
                this.$parent.toggleZoom(childFilter)
                this.$parent.update()
              }
            },
            icon: 'fas fa-search'
          },
          {
            id: 'keep',
            icon: 'fas fa-filter',
            name: () => {
              let match = this.bar[this.$parent.dataGetter.zoomable[[this.$parent.dataGetter.zoomable.length - 1]]].match(/(.*)\s\(|(.*)$/)
              if (match[1] == null) {
                return 'Keep only ' + match[0]
              }
              return 'Keep only ' + match[1]
            },
            if: () => {
              let drill_down = this.drillDown.value;
              if (drill_down === 'Auto') {
                let bestFilterValue = Math.max(...this.$parent.dataset.map(r => r.Main_Filter_Interest || 0));
                drill_down = this.$parent.dataset.filter(r => r.Main_Filter_Interest === bestFilterValue).map(r => r.Main_Filter_Type).unique()[0];
              }
              return this.$parent.dataGetter.getElementDepth(this.bar) === (this.$parent.dataGetter.zoomable.length - 1)
                && this.bar[this.$parent.dataGetter.filter[1]] !== "Empty_dimension"
                && this.bar.Type !== this.$parent.configuration.bars.oos.type
                && drill_down === "Steps then Dimensions"
            },
            click: () => {
              return new Promise(resolve => {
                let filter = this.bar[this.$parent.dataGetter.filter[1]]
                let value = this.bar[this.$parent.dataGetter.zoomable[[this.$parent.dataGetter.zoomable.length - 1]]].match(/(.*)\s\(|(.*)$/);
                // In case of segment who not contain SegmentValue (xSegmentCoef), take full match
                value = value[1] == null ? value[0] : value[1];
                this.$emit('updateFilter', {filter, value, keep: true})
                resolve()
              })
            }
          },
          {
            id: 'exclude',
            icon: 'fas fa-filter',
            name: () => {
              let match = this.bar[this.$parent.dataGetter.zoomable[[this.$parent.dataGetter.zoomable.length - 1]]].match(/(.*)\s\(|(.*)$/)
              if (match[1] == null) {
                return 'Exclude ' + match[0]
              }
              return 'Exclude ' + match[1]
            },
            if: () => {
              let drill_down = this.drillDown.value;
              if (drill_down === 'Auto') {
                let bestFilterValue = Math.max(...this.$parent.dataset.map(r => r.Main_Filter_Interest || 0));
                drill_down = this.$parent.dataset.filter(r => r.Main_Filter_Interest === bestFilterValue).map(r => r.Main_Filter_Type).unique()[0];
              }
              return this.$parent.dataGetter.getElementDepth(this.bar) === (this.$parent.dataGetter.zoomable.length - 1)
                  && this.bar[this.$parent.dataGetter.filter[1]] !== "Combined_Dimension"
                  && this.bar[this.$parent.dataGetter.filter[1]] !== "Empty_dimension"
                  && this.bar.Type !== this.$parent.configuration.bars.oos.type
                  && drill_down === "Steps then Dimensions"
            },
            click: () => {
              return new Promise(resolve => {
                let filter = this.bar[this.$parent.dataGetter.filter[1]]
                let value = this.bar[this.$parent.dataGetter.zoomable[[this.$parent.dataGetter.zoomable.length - 1]]].match(/(.*)\s\(|(.*)$/);
                value = value[1] == null ? value[0] : value[1];
                this.$store.dispatch('shared/updateFilters', {filter, value, keep: false}).then(() => resolve())
              })
            }
          },
          {
            id: 'split-by',
            name: 'Split by ...',
            icon: 'fa fa-eye',
            if: () => {
              let drill_down = this.$parent.drill_down;
              if (this.bar.Type === 'net' || this.bar.Type === 'net_OoS') return false
              let barsDepthHigherThanZero = this.$parent.dataGetter.getMaxDepth(this.$parent.waterfall_configuration.data) > 0
              let barIsNotInLockedScope = this.$parent.__zoomExist(this.$parent.__getZoomFromElement(this.bar, 1).zoom, this.$parent.waterfall_configuration.locks) === -1
              return barsDepthHigherThanZero && barIsNotInLockedScope && drill_down === 'Steps then Dimensions';
            },
            click: (event) => {
              if (this.minimal !== false) {
                this.$parent.setDimension(event.target.getAttribute('data-dimension'))
              }
              let inputs;
              if (this.$route.name.split(':')[1] === 'detect') {
                inputs = [{id: 'DimensionOut', value: event.target.getAttribute('data-dimension')}];
              } else {
                inputs = [{id: 'DetailedDimensionFilter', value: event.target.getAttribute('data-dimension')}];
              }
              this.$emit("input", inputs)
            },
            sub: () => {
              let toParse = this.$parent.waterfall_configuration.score.filter(d => d.StepNames === this.bar.Level_00);
              let lag = Math.min(...toParse.map(d => parseFloat(d.Interest_Score))) < 0 ? Math.min(...toParse.map(d => parseFloat(d.Interest_Score))) : 0;
              let total = toParse.map(d => parseFloat(d.Interest_Score)).reduce((partial_sum, a) => (Math.abs(partial_sum) + Math.abs(lag)) + Math.abs(a));
              let html = '<ul>';
              let figure = (score, format, total = 1, lag = 0) => {
                let bar = {};
                if (typeof format === "object") format = format[0]
                if (typeof score === "object") score = score[0]
                if (typeof lag === "object") lag = lag[0]
                switch (format) {
                  case '%':
                    bar.style = `width: ${(score < 0 ? 0 : score * 100)}%;${score * 100 < 1 ? 'border-radius: 50%;' : ''}`;
                    bar.suffix = '%';
                    bar.action = (e) =>  Math.trunc(e * 100) + (Math.trunc(((e * 100 % 1) * 100)) / 100);
                    break;
                  default:
                    bar.style = `width: ${(((score + Math.abs(lag)) / total) * 100)}%;${(((score + Math.abs(lag)) / total) * 100) < 1 ? 'border-radius: 50%;' : ''}`;
                    bar.suffix = '';
                    bar.action = (e) => e;
                    break;
                }
                return `<div class="figure"><span class="datama-label"><span class="datama-label-text">Interest Score:</span><span class="value">${bar.action(score)}${bar.suffix}</span></span><span class="bar" style="${bar.style}"></span></div>`;
              }
              let i = 0;
              while (i < toParse.length) {
                html += `<li class="${this.bar.Filter === toParse[i].DimensionNames ? 'selected' : ''}">
    <a id="d3-wf-rc-split-by-${i}" href="#" data-action="split-by" data-level="sub" data-dimension="${toParse[i].DimensionNames}">
        ${toParse[i].DimensionNames}
        ${figure(parseFloat(toParse[i].Interest_Score), '', parseFloat(total), parseFloat(lag))}
    </a>
</li>`
                i++;
              }
              return html + '</ul>'
            }
          },
          {
            id: 'split-by-dimensions-or-steps',
            name: 'Drill down ...',
            icon: 'fa fa-eye',
            if: () => {
              return (this.bar.Type === 'net' || this.bar.Type === 'net_OoS') && this.$parent.dataset.filter(r =>  r.Main_Filter_Interest != null && r.Main_Filter_Interest !== '').map(r => JSON.stringify({split: r.Main_Filter_Type, interest: r.Main_Filter_Interest})).unique().length > 1;
            },
            sub: () => {
              let toParse = this.$parent.dataset.filter(r =>  r.Main_Filter_Interest != null && r.Main_Filter_Interest !== '').map(r => JSON.stringify({split: r.Main_Filter_Type, interest: r.Main_Filter_Interest, text: r.Main_Filter, rank: parseFloat(r.Main_Filter_Rank)})).unique().map(r => JSON.parse(r));
              toParse.sort((a, b) => a.rank - b.rank)
              // toParse = this.$parent.waterfall_configuration.score.filter(d => d.StepNames === this.bar.Level_00);
              let lag = Math.min(...toParse.map(d => parseFloat(d.interest))) < 0 ? Math.min(...toParse.map(d => parseFloat(d.interest))) : 0;
              let total = toParse.map(d => parseFloat(d.interest)).reduce((partial_sum, a) => (Math.abs(partial_sum) + Math.abs(lag)) + Math.abs(a));
              let html = '<ul>';
              let figure = (score, format, total = 1, lag = 0) => {
                let bar = {};
                if (typeof format === "object") format = format[0]
                if (typeof score === "object") score = score[0]
                if (typeof lag === "object") lag = lag[0]
                switch (format) {
                  case '%':
                    bar.style = `width: ${(score < 0 ? 0 : score * 100)}%;${score * 100 < 1 ? 'border-radius: 50%;' : ''}`;
                    bar.suffix = '%';
                    bar.action = (e) =>  Math.trunc(e * 100) + (Math.trunc(((e * 100 % 1) * 100)) / 100);
                    break;
                  default:
                    bar.style = `width: ${(((score + Math.abs(lag)) / total) * 100)}%;${(((score + Math.abs(lag)) / total) * 100) < 1 ? 'border-radius: 50%;' : ''}`;
                    bar.suffix = '';
                    bar.action = (e) => parseFloat(e).toFixed(2);
                    break;
                }
                return `<div class="figure"><span class="datama-label"><span class="datama-label-text">Interest Score:</span><span class="value">${bar.action(score)}${bar.suffix}</span></span><span class="bar" style="${bar.style}"></span></div>`;
              }
              let i = 0;
              while (i < toParse.length) {
                html += `<li class="${this.$parent.drill_down === toParse[i].split && ((this.$parent.drill_down === 'Dimensions then Steps' && toParse[i].text === this.$parent.drill_down_dimension) || (this.$parent.drill_down !== 'Dimensions then Steps' && this.$parent.drill_down === toParse[i].split)) ? 'selected' : ''} ${toParse[i].split !== 'Steps then Dimensions' ? 'variant' : ''}">
    <a id="d3-wf-rc-split-by-dimensions-or-steps-${i}" href="#" data-action="split-by-dimensions-or-steps" data-level="sub" data-split="${toParse[i].split}" data-dimension="${toParse[i].text}">
        <span class="split-by-dimensions-or-steps-label"><span class="text">${toParse[i].text}</span><span class="detailed">${toParse[i].split}</span></span>
        ${figure(parseFloat(toParse[i].interest), '', parseFloat(total), parseFloat(lag))}
    </a>
</li>`
                $('body').off('click', `#d3-wf-rc-split-by-dimensions-or-steps-${i}`).on('click', `#d3-wf-rc-split-by-dimensions-or-steps-${i}`, (event) => {
                  event.preventDefault()
                  $('.d3-wf-right-click').remove();
                  return new Promise(resolve => {
                    let value = event.target.getAttribute('data-split');
                    let dimension = event.target.getAttribute('data-dimension');
                    this.$parent.setDrillDown(value, dimension)
                  })
                })
                i++;
              }
              return html + '</ul>'
            }
          },
          {
            id: 'selected-step',
            name: 'Selected step ...',
            icon: 'fa fa-search',
            if: () => {
              return this.$parent.bridge && this.$route.name.split(':')[1] !== 'detect'
            },
            click: (e) => {
              e.preventDefault()
              return new Promise(resolve => {
                let value = e.target.getAttribute('data-step');
                let inputs;
                if (this.$route.name.split(':')[1] === 'detect') {
                  inputs = [{id: 'stepSelectAnomaly', value}];
                } else {
                  inputs = [{id: 'StepFilter', value}];
                }
                this.$emit("input", inputs)
                let data = this.$parent.dataset;
                data = data.filter(element => element.Filter_Level_00 === value)
                let filter = [
                  "Filter",
                  "Filter_Rank"
                ];
                this.$parent.dataGetter = new DataWaterfall(data, false, filter)
                this.$parent.waterfall_configuration.zooms = [JSON.stringify({
                  filter: {
                    Filter: data.filter(element => element.Filter_Rank.toString() === '1')[0].Filter
                  },
                  zoom: {
                    Level_00: value,
                    Level_01: false
                  }
                })]
                let sub = this.$parent.__getDataFromZooms()
                let i = 0;
                while (i < sub.length) {
                  if (sub[i].Type !== 'net') {
                    this.$parent.waterfall_configuration.zooms.push(JSON.stringify({
                      filter: {
                        Filter: data.filter(element => element.Filter_Rank.toString() === '1')[0].Filter
                      },
                      zoom: this.$parent.__getZoomableFromElement(sub[i])
                    }))
                  }
                  i++;
                }
                this.$parent.svg.selectAll('rect').remove();
                this.$parent.svg.selectAll('text').remove();
                this.$parent.setTitle(value + ' - ' + data.filter(element => element.Filter_Rank.toString() === '1')[0].Filter)
                this.$parent.update()
                resolve()
              })
            },
            sub: () => {
              let steps = this.$store.state.shared.steps.map(r => r.Step_Name)
              let html = '<ul>';
              let i = 0;
              while (i < steps.length) {
                html += `<li class="${this.bar.Filter_Level_00.replace(/\s\(Total\)/g, '') === steps[i].replace(/\s\(Total\)/g, '') ? 'selected' : ''}">
    <a id="d3-wf-rc-selected-step-${i}" href="#" data-action="selected-step" data-level="sub" data-step="${steps[i]}">
        ${steps[i]}
    </a>
</li>`
                i++;
              }
              return html + '</ul>'
            }
          },
          {
            id: 'remaining',
            name: 'Remaining',
            icon: 'fas fa-plus',
            if: () => this.bar.unique.indexOf('waterfall-remain') !== -1 && !(this.bar.Type === 'net' || this.bar.Type === 'net_OoS'),
            click: () => {
              this.$parent.remaining.value++;
              this.$parent.update();
            }
          },
          {
            id: 'skip_steps',
            name: () => {
              let steps = this.$store.state.shared.steps.filter(step => step.active === false);
              if (steps.length > 0) {
                return 'Expand';
              }
              return 'Collapse';
            },
            icon: () => this.$store.state.shared.steps.filter(step => step.active === false).length > 0 ?
                'fas fa-plus-square' : 'fas fa-minus-square',
            if: () => this.$parent.dataGetter.getElementDepth(this.bar) === 0 && !(this.bar.Type === 'net' || this.bar.Type === 'net_OoS'),
            sub: () => {
              let i = 0;
              let html = `<ul>`;
              // mdi mdi-plus-box-outline
              while (i < this.$store.state.shared.steps.length) {
                html += `<li><a id="d3-wf-skip-${i}" href="#" data-action="skip_steps" data-step="${this.$store.state.shared.steps[i].Step_Name}"><span class="${!this.$store.state.shared.steps[i].active ? 'mdi mdi-close' : 'mdi mdi-check' }" style="font-size: 14px;width: 20px;text-align: left;display: inline-block;margin-left:-5px;"></span>${this.$store.state.shared.steps[i].Step_Name}</a></li>`
                i++;
              }
              return html + '</ul>'
            },
            click: (e) => {
              let step = e.target.getAttribute('data-step');
              let indice = this.$store.state.shared.steps.map(({Step_Name}) => Step_Name).indexOf(step);
              let {steps, dimensions, metrics} = this.$store.state.shared, step_total = this.$store.state.shared.inputs.step_total;
              let tmp_step = this.$store.state.shared.steps[indice];
              tmp_step.active = !tmp_step.active
              steps[indice] = tmp_step;
              return this.$store.dispatch('shared/setMetricRelation', {steps, dimensions, metrics, step_total}).then(() => {
                return this.$store.dispatch('shared/compute')
              })
            }
          },
          {
            id: 'lock',
            icon: () => this.$parent.__zoomExist(this.$parent.__getZoomFromElement(this.bar, 2).zoom, this.$parent.waterfall_configuration.locks) === -1 ? "fas fa-lock" : "fas fa-unlock",
            name: () => this.$parent.__zoomExist(this.$parent.__getZoomFromElement(this.bar, 2).zoom, this.$parent.waterfall_configuration.locks) === -1 ? 'Lock' : 'Unlock',
            if: () => this.$parent.dataGetter.getChild(this.bar, this.$parent.waterfall_configuration.data).length > 0,
            click: (e) => {
              e.preventDefault()
              return new Promise(resolve => {
                this.$parent.addLock(this.bar)
                resolve()
              })
            }
          }
        ],
        bridge: []
      },
      html: '',
      orientation: 'left'
    }
  },
  computed: {
    drillDown: {
      get() {
        return this.$store.state.shared.inputs.drill_down
      },
      set(v) {
        this.$store.dispatch('shared/updateInputValue', v)
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.render()
    })
  },
  methods: {
    template() {
      let html = `<ul id="d3-wf-right-click-${this.identifier}" class="d3-wf-right-click">`;
      let i = 0;
      while (i < this.elements[this.mode].length) {
        let element = this.elements[this.mode][i];
        if (element.if == null || (typeof element.if === 'function' && element.if())) {
          let sub;
          if (typeof element.sub === 'function') {
            sub = element.sub();
          } else {
            sub = "";
          }
          html += `<li><a id="d3-wf-rc-${element.id}" data-action="${element.id}" href="#"><i class="${typeof element.icon === 'function' ? element.icon() : element.icon}"></i>${typeof element.name === "function" ? element.name() : element.name}</a>${sub}</li>`
          this.actionable = true;
          this.$refs.context.removeEventListener('click', (e) => this.handleClick(e, element))
          this.$refs.context.addEventListener('click', (e) => this.handleClick(e, element), false)
        }
        i++;
      }
      return html + "</ul>";
    },
    handleClick(event, element) {
      if (this.actionable) {
        event.preventDefault();
        let action = event.target.getAttribute('data-action');
        this.elements[this.mode].filter((element) => element.id === action)[0].click(event)
        if (element.keepContextMenu !== true) {
          this.$emit('close')
        }
        this.actionable = false;
      }
    },
    render() {
      this.html = this.template()
    }
  }
}
</script>
<style>
.context-menu {
  position: fixed;
  background: white;
  z-index: 999;
}
</style>