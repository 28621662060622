<template>
  <svg
    id="board"
    width="100%"
    height="100%"
  >
    <g
      v-for="(p, index) in renderedPathes"
      :key="'renderedPathes'+index"
      class="clickable"
      @click="selectArrow(index)"
    >
      <path
        v-if="outline"
        :d="p.data"
        :style="p.outlineStyle"
        :class="developer_mode ? ('link link-' + (p.delay != null ? p.delay : index)) : ''"
      />
      <path
        :d="p.data"
        :style="style(p,index)"
        :class="developer_mode ? ('link link-' + (p.delay != null ? p.delay : index)) : ''"
      />
      <text
        v-if="debug"
        :x="p.pos.x" 
        :y="p.pos.y"
        fill="#DD4F39"
        class="debug-line-id-class"
        :style="`font-size:${9*options.scale}px !important;`"
      >
        (link #{{ p.id }})
      </text>
      <text 
        v-if="debug"
        :x="p.pos.x" 
        :y="p.pos.y+12*options.scale"
        fill="#DD4F39"
        class="debug-line-id-class"
        :style="`font-size:${9*options.scale}px !important;`"
      >
        {{ "#"+p.originID }} → {{ "#"+p.targetID }} 
      </text>
        
      
    </g>
    <g
      v-for="(a, index) in renderedArrows"
      :key="'renderedArrows'+index"
      class="clickable" 
      @click="selectArrow(index)"
    >
      <path
        d="M -1 -1 L 0 1 L 1 -1 z"
        :style="style(a,index)"
        :transform="a.transform"
      />
    </g>
  </svg>
</template>

<script>
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
import {developer_mode} from "@/utils/developer_mode";

export default {
  name: 'VueLink',
  mixins: [
    DataMaLogicAbstract
  ],
  props: {
    lines: {
      type: Array,
      default() {
        return []
      }
    },
    outline: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data(){
    return {
      // mouseover:false,
    }
  },
  computed: {
    developer_mode() {
      return developer_mode()
    },
    selected(){
      return this.$store.state.prep.selected_link
    },
    /**
     * Render lines
     * @returns {[]|*[]}
     */
    renderedPathes() {
      if (!this.lines) {
        return []
      }

      let pathes = []
      
      this.lines.forEach(l => {
        let dist = this.distance(l.x1, l.y1, l.x2, l.y2) * .25
        let x_center = 0.45*(l.x2 + l.x1) //* this.options.scale
        let y_center = 0.625*(l.y2 + l.y1) //* this.options.scale
        pathes.push({
          data: `M ${l.x1 + (15 * this.options.scale)}, ${l.y1 + (17 * this.options.scale)} C ${(l.x1 + dist)}, ${l.y1 + ((l.slot) ? (20 * this.options.scale) : (17 * this.options.scale))}, ${(l.x2 - dist)}, ${l.y2 + ((l.slot) ? (75 * this.options.scale) : (17 * this.options.scale))}, ${!l.slot ? l.x2 - (13 * this.options.scale) : l.x2 + 1 - (1 * this.options.scale)}, ${l.y2 + ((l.slot) ? (38 * this.options.scale) : (17 * this.options.scale))} `,
          pos:{x:x_center,y:y_center},
          style: l.style,
          id:l.id,
          originID:l.originID,
          targetID:l.targetID,
          outlineStyle: l.outlineStyle,
          delay: l.delay
        })
      })

      return pathes
    },
    /**
     * Render arrows
     * @returns {[]|*[]}
     */
    renderedArrows() {
      if (!this.lines) {
        return []
      }

      let arrows = []
      this.lines.forEach(l => {
        // l.x1, l.y1, l.x2, l.y2
        let pos = this.computeConnectionPoint(l.x1, l.y1, l.x2 + (l.slot ? (11 * this.options.scale) : -(5 * this.options.scale)), l.y2 + ((l.slot) ? (37 * this.options.scale) : (17 * this.options.scale)), 1)
        let degrees = (l.slot) ? 225 : 270;
        arrows.push({
          transform: `translate(${pos.x - 10}, ${pos.y}) rotate(${degrees}) scale(0.75)`,
          style: {
            stroke: "#525f7f",//l.style.stroke,
            strokeWidth: l.style.strokeWidth * 3,
            fill: "#525f7f"//l.style.stroke
          }
        })
      })

      return arrows
    }
  },
  methods: {
    style(elt,index){
      if(index === this.selected){
        return {
          fill:"#12867a",
          stroke:"#12867a",
          strokeWidth:elt.style.strokeWidth
        }
      }
      else{
        return elt.style
      }
    },
    selectArrow(index){
      this.$store.state.prep.selected_link = index
    },
    /**
     * Get distance between 2 points
     * @param x1
     * @param y1
     * @param x2
     * @param y2
     * @returns {number}
     */
    distance(x1, y1, x2, y2) {
      return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1))
    },
    /**
     * Each step of links to get curve
     * @param x1
     * @param y1
     * @param x2
     * @param y2
     * @param t
     * @returns {{x: number, y: number}}
     */
    computeConnectionPoint(x1, y1, x2, y2, t) {
      let dist = this.distance(x1, y1, x2, y2)
      let p0 = {x: x1, y: y1}
      let p1 = {x: x1 + dist * 0.25, y: y1}
      let p2 = {x: x2 - dist * 0.25, y: y2}
      let p3 = {x: x2, y: y2}

      let c1 = (1 - t) * (1 - t) * (1 - t)
      let c2 = 3 * ((1 - t) * (1 - t)) * t
      let c3 = 3 * (1 - t) * (t * t)
      let c4 = t * t * t

      let x = c1 * p0.x + c2 * p1.x + c3 * p2.x + c4 * p3.x
      let y = c1 * p0.y + c2 * p1.y + c3 * p2.y + c4 * p3.y
      return {x: x, y: y}
    }
  }
}
</script>

<style  lang="scss"  scoped>
@import "@/theme/config/_colors.scss";

.debug-line-class:hover{
  text-decoration: none !important;
}
.debug-line-id-class{
  font-size: 9px !important;
  border-radius: 10px;
  text-decoration:none;
}

.clickable{
  cursor: pointer;
  position: relative;
}
</style>
