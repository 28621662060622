export function getColumns(sumdata) {
  return sumdata.columns.map(col => {
    let colname = col.fieldName.replace(/(SOMME|SUM|MOY|MEDIAN|CNT|CNTD)\(|\)/g,"") // Remove functions and parenthesis
    colname = colname.replace(/^\d+\_/g,"") // Remove column names like 1_maCol
    return colname
  })
}

export function buildCSV(sumdata) {
  return new Promise((resolve, reject) => {
    let csv = ""
  
    try {
      // add header to csv
      csv += getColumns(sumdata).join(',')+'\n'

      // add rows to csv
      sumdata.data.forEach(line => {
        csv += line.map((row, index) => {
          if(sumdata.columns[index].dataType === "date") {
            return row.value
          } else { 
            return row.nativeValue 
          }
        }).join(',')+'\n'
      })
      return resolve(csv)
    }
    catch (error) {
      reject(error)
    }
  })
}