<template>
  <v-dialog
    id="datama-metric-relation-dialog"
    v-model="open"
    :width="window.innerWidth - 200"
    :min-width="600"
    :max-width="window.innerWidth - 200"
    :min-height="500"
    :max-height="window.innerHeight - 200"
    persistent
  >
    <v-card class="w-100 h-100 p-3 pl-2 pr-2">
      <v-card-title class="pb-5">
        Metrics relation
        <span class="ml-auto mr-0">
          <span
            class="mr-2"
          >
            <v-icon
              v-b-tooltip.hover.top="'See related documentation'"
              @click="openDocumentation()"
            >
              mdi-information-outline
            </v-icon>
          </span>
          <v-icon
            v-b-tooltip.hover.lefttop="'Close'"
            class="mr-1 ml-3"
            @click.stop="closeDialog"
          >
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-card-text class="pb-0">
        <DataMaMetricRelation @error="e => error = e" />
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          class="saveProperty transparent shadow-none black--text"
          @click.prevent="open = false"
        >
          Close
        </v-btn>
        <v-btn
          class="saveProperty"
          @click.prevent="loadMetricRelation"
        >
          Load
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DataMaMetricRelation from "../../Solutions/Structure/Inputs/DataMaMetricRelation.vue";
import {COMPUTE_STATES} from "@/config";
import {solution} from "@/config/solution";

export default {
  name: 'MetricRelationDialog',
  components: {
    DataMaMetricRelation
  },
  props: {
    minify: {
      type: Boolean,
      default() {
        return false;
      }
    },
    live: {
      type: Boolean,
      default() {
        return false;
      }
    },
    header: {
      type: Boolean,
      default() {
        return false;
      }
    },
    button: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      error: false
    }
  },
  computed: {
    open: {
      get() {
        return this.$store.state.shared.core.showMetricRelationDialog
      },
      set() {
        this.$store.dispatch('shared/toggleDialog', {name: 'metricRelation'})
      }
    },
    window() {
      return window;
    },
    steps: {
      get() {
        return this.$store.state.shared.steps
      },
      set(v) {
        this.$set(this.$store.state.shared, 'steps', v)
      }
    },
    dimensions: {
      get() {
        return this.$store.state.shared.dimensions
      },
      set(v) {
        this.$set(this.$store.state.shared, 'dimensions', v)
      }
    },
    metrics: {
      get() {
        return this.$store.state.shared.metrics
      },
      set(v) {
        this.$set(this.$store.state.shared, 'metrics', v)
      }
    },
    total: {
      get() {
        return this.$store.state.shared.inputs.step_total
      },
      set(v) {
        this.$set(this.$store.state.shared.inputs, 'step_total', v)
      }
    }
  },
  methods: {
    closeDialog() {
      this.$set(this, 'open', false)
      this.$set(this.$store.state.shared.core, 'showMetricRelation', false)
    },
    openDocumentation() {
      window.open(solution.base.documentation.metrics_relation, '_blank').focus()
    },
    loadMetricRelation() {
      this.$set(this, 'open', false)
      this.$set(this.$store.state.shared.core, 'showMetricRelation', false)
      if (this.$store.state.compute.differences.length) {
        this.$store.dispatch('shared/compute')
      }
    }
  }
}
</script>