<script>
export default {
  name: 'AdminAbstract',
  props: {},
  data() {
    return {}
  },
  computed: {
    areOrgsLoading() {
      return this.$store.state.spinners.filter(({id}) => id === 'admin/getOrganization')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'admin/getOrganization')[0].state === true
    },
    adminOrganizations() {
      return this.$store.state.admin.panel.organizations;
    },
    adminOrgMembers() {
      return this.$store.state.admin.panel.org_members;
    },
    users() {
      return this.$store.state.admin.panel.users
    },
    user_roles() {
      return this.$store.state.admin.panel.user_roles
    },
    org_roles() {
      return this.$store.state.admin.panel.org_roles
    },
    roles() {
      return this.$store.state.admin.panel.roles
    },
  },
}
</script>