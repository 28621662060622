// class LockHandler{
//     constructor(store,lockStateName,lockActionName,possibleStates){
//         this.__store = store;//private
//         this.lockStateName = lockStateName;
//         this.lockActionName = lockActionName;
//         this.possibleStates = possibleStates;
//     }
//     ChangeState(state){
//         this.__store.dispatch(this.lockActionName,state);
//     }
//     ASIDE_UNCOMMITED_CHANGE_CSV_FLOW(){
//         if (this.__store.state[lockStateName] == this.possibleStates[0]){
//             if (typeof callbackByState[this.possibleStates[0]] == "function"){
//                 callbackByState[this.possibleStates[0]]()
//             }
//             else console.error(
//                 "LockHandler->ASIDE_UNCOMMITED_CHANGE_CSV_FLOW: BAD CALLBACK",
//                 callbackByState,
//                 this.possibleStates[0]
//             );
//         }
//         else if (this.__store.state[lockStateName] == this.possibleStates[1]){
//             if (typeof callbackByState[this.possibleStates[1]] == "function"){
                
//             }
//             else console.error(
//                 "LockHandler->ASIDE_UNCOMMITED_CHANGE_CSV_FLOW: BAD CALLBACK",
//                 callbackByState,
//                 this.possibleStates[1]
//             );
//         }
//         else if (this.__store.state[lockStateName] == this.possibleStates[2]){
//             if (typeof callbackByState[this.possibleStates[2]] == "function"){
                
//             }
//             else console.error(
//                 "LockHandler->ASIDE_UNCOMMITED_CHANGE_CSV_FLOW: BAD CALLBACK",
//                 callbackByState,
//                 this.possibleStates[2]
//             );
//         }
//         else{
//             console.error("LockHandler->ASIDE_UNCOMMITED_CHANGE_CSV_FLOW: BAD STATE")
//         }
        
//     }
// }
/*? the code above was an attempt to programaticaly define locks and their flows
 *  but that approach is way too overkill and will be refactored anyways if we're going to use a state lock manager
    such as XState
*/
function callOrError(callback,errorMessage){
    if (typeof callback == "function"){
        callback()
    }
    else console.error(errorMessage);
}
export const LocksHandler = {
    // can be refactored into a builder class but it's better to use XState at that point
    /*this is a state that locks block selection; block deselection; Toogling Aside component hand 
            * it has 3 possible values:
            *    - 0-> FREE: no files have been added
            *    - 1-> UNCOMMITED: file has been added but not commited -> asks if you're sure you want to cancel the loading
            *    - 2-> READY_TO_UNLOAD: user wants to cancel the loading
            * 0 -> add file -> 1 -> loadfile -> 0
            * 0 -> lockedEvent(success)
            * 1 -> lockedEvent(block) -> 2 && show tooltipYesNo 
            *                                   -> choose Yes -> 0
            *                                   -> choose No -> 1
            *                                   -> clickoutside || lockedEvent(fail) -> 0
            * 
            * add file is in : DragDrop->mounted
            * loadfile : DragDrop->submitFiles
            * lockedEvents : Prep ->CLick which includes( SelectBlock , DeselectBlock); 
            *                Aside -> ToogleAside
            * //! this is the flow handler
    */
    /*
    * function ASIDE_UNCOMMITED_CHANGE_FLOW
    * @params- self : used to pass the VueContainer to a deeper scope so basically self = this
    * @params- calllbackByState : this object holds the callbacks/functions to call once a certain
    * is met and it's format is : {"STATE_NAME":()=>{//code goes here},...}
    * You only have to pass it logic to the function; try not to modify the lock state outside of
    * of this function, unless it's on the locking Event (example Loading a CSV file is a locking event
    * and it should change the state from FREE to UNCOMMITED )
    * */
    ASIDE_UNCOMMITED_CHANGE_FLOW(self,calllbackByState){
        const MAX_CLICK_COUNT = 1;
        const state = self.$store.state.ASIDE_UNCOMMITED_CHANGE;
        if (state=="FREE" && calllbackByState["FREE"]){
            callOrError(calllbackByState["FREE"],"ASIDE_UNCOMMITED_CHANGE_FLOW: Bad Callback ; state 'FREE'")
        }
        if(state=="UNCOMMITED" && calllbackByState["UNCOMMITED"]){
            self.$store.dispatch("showAsideUncommitedChangeTooltip",true);
            callOrError(calllbackByState["UNCOMMITED"],"ASIDE_UNCOMMITED_CHANGE_FLOW: Bad Callback ; state 1");
            let triggerThresholdReached = self.$store.state.ASIDE_UNCOMMITED_CHANGE_LOCKEDEVENT_COUNT==MAX_CLICK_COUNT;
            if (triggerThresholdReached) self.$store.dispatch("updateAsideUncommitedChange","READY_TO_UNLOAD");
        }
        // bug found deselect and select gets called at the same time creating thus an issue for the state!
        if(state=="READY_TO_UNLOAD" && calllbackByState["READY_TO_UNLOAD"]){
            self.$store.dispatch("showAsideUncommitedChangeTooltip",false);
            self.$store.dispatch("updateAsideUncommitedChange","FREE");  
            callOrError(calllbackByState["READY_TO_UNLOAD"],"ASIDE_UNCOMMITED_CHANGE_FLOW: Bad Callback ; state 2");
        }
    },
    MENUTOP_UNCOMMITED_CHANGE_FLOW(self,calllbackByState){
        const state = self.$store.state.prep.MENUTOP_UNCOMMITED_CHANGE;
        if (state=="FREE" && calllbackByState["FREE"]){
            callOrError(calllbackByState["FREE"],"MENUTOP_UNCOMMITED_CHANGE_FLOW: Bad Callback ; state 'FREE'")
        }
        if(state=="LOCKED" && calllbackByState["LOCKED"]){
            callOrError(calllbackByState["LOCKED"],"MENUTOP_UNCOMMITED_CHANGE_FLOW: Bad Callback ; state 'LOCKED'");
        }
    }
}