<!--    <v-card>-->
<!--      <v-toolbar dark :color="options.color" dense flat>-->
<!--        <v-toolbar-title class="white&#45;&#45;text">{{ title }}</v-toolbar-title>-->
<!--      </v-toolbar>-->
<!--      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>-->
<!--      <v-card-actions class="pt-0">-->
<!--        <v-spacer/>-->
<!--        <v-btn color="primary darken-1" text @click.native="agree">Yes</v-btn>-->
<!--        <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>-->
<!--      </v-card-actions>-->
<!--    </v-card>-->

<template> 
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title class="text-h5">
        Accept {{ title }}<br> Terms & Conditions ?
      </v-card-title>

      <v-card-text>
        <span
          v-html="message"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="green darken-1"
          text
          @click.native="cancel"
        >
          Disagree
        </v-btn>

        <v-btn
          color="green darken-1"
          text
          @click.native="agree"
        >
          Agree
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <ConsentPopupLicense ref="confirm"></ConsentPopupLicense>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <ConsentPopupLicense ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  name: "ConsentPopupLicense",
  data: () => ({
    dialog: false,
    service: "",
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 200
    }
  }),
  methods: {
    open(title, service, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.service = service
      if(service === undefined){
        this.resolve(false)
        this.dialog = false
      }
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.$store.dispatch("artificialIntelligence/consentToTos",{
        params: {
          service: this.service
        }
      }).then(() => {
          this.resolve(true)
          this.dialog = false
      }).catch(async (err) => {
        alert("An error occurred while trying to consent to servie : "+this.service)
        console.error(err)
        this.resolve(false)
        this.dialog = false
      })

    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>