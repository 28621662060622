<template>
  <v-icon @click="export_ppt">
    mdi-file-powerpoint-outline
  </v-icon>
</template>
<script>
import pptxgen from 'pptxgenjs';
import domtoimage from "dom-to-image";
var IMAGE_X = 0.5,
    IMAGE_H = 2.5,
    IMAGE_Y = 0.85,
    IMAGE_CENTERED = true,
    COMMENT_X = 0.5,
    COMMENT_Y = 3.35,
    COMMENT_W = 9,
    COMMENT_H = 3,
    COMMENT_FONT_SIZE = 9,
    COMMENT_ALIGN_Y = "top",
    TITLE_X = 0.5,
    TITLE_Y = 0.5;
export default {
  name: 'PptExport',
  props: {
    chart: {
      type: [Object, HTMLElement, Boolean],
      default() {
        return null
      }
    },
    title: {
      type: [Object, HTMLElement],
      default() {
        return null
      }
    },
    comment: {
      type: [Object, HTMLElement, Boolean],
      default() {
        return false
      }
    },
    name: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      configuration: {}
    }
  },
  methods: {
    export_ppt() {
      // reset configuration to prevent duplicates
      this.configuration = {
        comment: {
          contents: [],
              slideConfiguration: [],
              id: '',
              isTitle: false
        },
        title: {
          contents: [],
              slideConfiguration: [],
              id: '',
              isTitle: false
        }
      }
      let text_title = this.getConfiguration(this.title.childNodes, 'title').slideConfiguration,
          text_comment = this.comment ? this.getConfiguration(this.comment.querySelector('div[data-exported="data-comment"]').childNodes, 'comment').slideConfiguration : '';
      let maxLineNbr = 24,
          commentLinesNbr = this.comment ? this.comment.querySelector('div[data-exported="data-comment"]').innerText.split("\n").length - 1 : 1,
          commentSize = commentLinesNbr / maxLineNbr,
          imageHeight = 2.5;
      if (commentSize > 0.5) {
        imageHeight *= commentSize
      } else {
        if (commentSize < 0.5) {
          imageHeight *= (1 + (0.5 - commentSize))
        } else {
          imageHeight = 2.5;
        }
      }
      if (text_comment === "") {
        var old_image_h = IMAGE_H,
            old_image_x = IMAGE_X;
        IMAGE_H = 5
        IMAGE_X = 1
      }
      if (this.chart != null) {
        let ratio = this.chart.clientWidth / this.chart.clientHeight
        // If image overflow
        if (imageHeight * ratio > 9) {
          while (imageHeight * ratio > 9) {
            imageHeight -= 0.5
          }
        }
        let plot = this.chart.querySelector('svg');
        let promise;
        if (plot) {
          plot.setAttribute('fill', 'white')
          plot.setAttribute('stroke-width', '0')
          plot = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(plot))))
          promise = new Promise(resolve => resolve(plot))
        } else {
          promise = this.__getImage(this.chart)
        }
        promise.then((url) => {
          let pptx = new pptxgen(),
              slide = pptx.addSlide(text_title);
          slide.addText(
              text_title,
              {
                x: TITLE_X,
                y: TITLE_Y,
                align: pptx.AlignH.left
              }
          );
          slide.addText(
              text_comment,
              {
                x: COMMENT_X,
                y: 1 + imageHeight,
                w: COMMENT_W,
                h: COMMENT_H,
                autoFit: true,
                isTextBox: true,
                fontSize: COMMENT_FONT_SIZE,
                valign: COMMENT_ALIGN_Y
              }
          )
          slide.addImage({
            data: url,
            w: imageHeight * ratio,
            h: imageHeight,
            y: IMAGE_Y,
            x: (IMAGE_CENTERED) ? 5 - ((imageHeight * ratio) / 2) : 1
          })
          this.__footer(slide, false)
          pptx.writeFile(this.name + ".pptx")
        })
      } else {
        let pptx = new pptxgen(),
            slide = pptx.addSlide(text_title);
        slide.addText(
            text_title,
            {
              x: TITLE_X,
              y: TITLE_Y,
              align: pptx.AlignH.left
            }
        );
        slide.addText(
            text_comment,
            {
              x: COMMENT_X,
              y: COMMENT_Y,
              w: COMMENT_W,
              h: COMMENT_H,
              autoFit: true,
              isTextBox: true,
              fontSize: COMMENT_FONT_SIZE,
              valign: COMMENT_ALIGN_Y
            }
        )
        this.__footer(slide, true)
        pptx.writeFile("DataMa Export.pptx")
      }
      if (text_comment === "") {
        IMAGE_X = old_image_x
        IMAGE_H = old_image_h
      }
    },
    __getImage(tab) {
      return domtoimage.toPng(tab)
    },
    __footer(slide, footer = true) {
      if (footer) {
        slide.addText("Powered with", {x: 8.4, y: 5.5, fontSize: 8})
        slide.addImage({
          data: "data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJoZWFydCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWhlYXJ0IGZhLXctMTYiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iIzE0OTU4OCIgZD0iTTQ2Mi4zIDYyLjZDNDA3LjUgMTUuOSAzMjYgMjQuMyAyNzUuNyA3Ni4yTDI1NiA5Ni41bC0xOS43LTIwLjNDMTg2LjEgMjQuMyAxMDQuNSAxNS45IDQ5LjcgNjIuNmMtNjIuOCA1My42LTY2LjEgMTQ5LjgtOS45IDIwNy45bDE5My41IDE5OS44YzEyLjUgMTIuOSAzMi44IDEyLjkgNDUuMyAwbDE5My41LTE5OS44YzU2LjMtNTguMSA1My0xNTQuMy05LjgtMjA3Ljl6Ij48L3BhdGg+PC9zdmc+Cg==",
          x: 9.175,
          y: 5.45,
          w: 0.125,
          h: 0.125
        })
        slide.addText("by DataMa", {x: 9.27, y: 5.5, fontSize: 8, hyperLink: {url: "https://www.datama.io/"}})
      } else {
        slide.addText("Powered with love by DataMa", {x: 8.4, y: 5.5, fontSize: 8})
      }
    },
    /**
     * Convert 0<->255 int to hex
     * @param c
     * @returns {string}
     */
    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    },
    /**
     * convert color to hex
     * @param string
     * @returns {string}
     */
    rgbToHex(string) {
      let hex;
      if (string.indexOf("rgb(") !== -1) {
        string = string.replace("rgb(", "").replace(")", "").trim()
        string = (string.split(",").length === 3) ? string.replace(/\s/g, "").split(",") : string.split(" ")
        hex = this.componentToHex(parseInt(string[0])) + this.componentToHex(parseInt(string[1])) + this.componentToHex(parseInt(string[2]));
      } else {
        if (string.length !== 6 && string.split(" ") === 3) string = this.componentToHex(parseInt(string.split(" ").join("")[0])) + this.componentToHex(parseInt(string.split(" ").join("")[1])) + this.componentToHex(parseInt(string.split(" ").join("")[2]))
        hex = string.replace("#", "")
      }
      return hex
    },
    /**
     * Return parsed configuration for powerpoint
     * @returns {[]}
     */
    getConfiguration(contents_to_parse, type='title') {
      return this.__parse(contents_to_parse, type);
    },
    /**
     * Function who build a whole line format
     * @param parent
     * @param bullet
     * @param depth
     * @param forceBreakLine
     * @param type
     * @private
     */
    __line(parent, bullet = false, depth = 0, forceBreakLine = false, type) {
      // If it is a single text without parent
      if (parent.localName == null) {
        this.configuration[type].slideConfiguration.push({
          text: (this.configuration[type].slideConfiguration.length > 0 && parent.textContent[0] !== ' ' && parent.textContent[0] !== ' ' ? " " : '') + parent.textContent + (parent.textContent[parent.textContent.length - 1] === ' ' ? '' : " "),
          options: {
            breakLine: forceBreakLine,
            bullet: bullet,
            indentLevel: depth
          }
        })
        return;
      }
      // Define base vars
      let lineElements = parent.childNodes,
          lineElementIndex = 0,
          breakLine = false,
          bold = false,
          wasBullet = bullet,
          forceBold = parent.localName === "b",
          color = "",
          skipped = 0,
          boldCount = 0;
      // If we want to force bold on whole line
      if (parent.localName === "b") {
        forceBold = true
      }
      // Parsing line block
      while (lineElementIndex < lineElements.length) {
        // Readability
        let current = lineElements[lineElementIndex],
            text = current.innerText || current.textContent;
        // If it is a final block
        if (current.children == null || current.children.length < 2) {
          // If it is not empty block
          if (text.trim() !== "") {
            // Getting inner text
            text = text.replace(/\n/g, "")
            // Do we want to break line
            breakLine = lineElementIndex === lineElements.length - 1 && forceBreakLine === false
            if (type === 'title') breakLine = false
            // Retrieve html color
            color = (current.style != null && current.style.color !== "") ? current.style.color.replace("#", "") : "000000"
            if (color === "000000" && current.parentNode != null && (current.parentNode.style.color !== "" && current.parentNode.style.color.replace("#", "") !== "000000")) color = current.parentNode.style.color
            // Do we want bold
            bold = forceBold || current.localName === "b" || boldCount > 0
            // Define configuration
            let configuration = {
              text: ((this.configuration[type].slideConfiguration.length > 0 && text[0] !== ' ' && text[0] !== ' ' ? " " : '') + text + (text[text.length - 1] === ' ' ? '' : " ")).replace(/(\s|\t)+/g, ' '),
              options: {
                breakLine: breakLine,
                bullet: bullet,
                bold: bold,
                color: this.rgbToHex(color)
              }
            }
            // Indent to right by the depth of bullet list
            if (wasBullet) configuration.options.indentLevel = depth
            // Pushing configuration
            this.configuration[type].slideConfiguration.push(configuration)
            // If we want to keep bullet state (more line in a point)
            if ((bullet && lineElementIndex === 0)
                || (bullet && lineElementIndex === skipped)) bullet = false
          } else {
            // Need to skip empty text block
            skipped++;
          }
          // When we add subelement as first level element we need to count number of element added
          if (boldCount > 0) boldCount--
          lineElementIndex++;
        } else {
          if (current.tagName === 'UL') {
            return this.__list(parent, depth + 1, type)
            lineElementIndex++;
          } else {
            /**
             * Subelement detected (like <b>text<span color red>text</span>text</b>
             * Add them to first level
             */
            let j = 0;
            // Define the translation of bold
            boldCount = current.children.length;
            lineElements.splice(lineElementIndex, 1)
            while (j < current.children.length) {
              lineElements.splice(lineElementIndex + j, 0, current.children[j])
              j++
            }
          }
        }
      }
    },
    /**
     * List as bullet point recursive
     * @param list
     * @param depth
     * @param type
     * @private
     */
    __list(list, depth = 0, type) {
      let children = list.childNodes,
          itemIndex = 0;
      while (itemIndex < children.length) {
        let item = children[itemIndex]
        switch (item.localName) {
          case "li":
            this.__line(item, true, depth, false, type)
            break;
          case "ul":
            this.__list(item, depth + 1, type)
            break
          default:
            break;
        }
        itemIndex++
      }
      if (depth === 0) this.configuration[type].slideConfiguration.push({text: "", options: { bullet: false, breakLine: true }})
    },
    /**
     * Parse a comment block
     * @param contents
     * @returns {Comment}
     * @param type
     * @private
     */
    __parse(contents = [], type) {
      let contentIndex = 0;
      let text;
      while (contentIndex < contents.length) {
        let current = contents[contentIndex];
        switch (current.localName) {
          case "div":
            this.__parse(current.childNodes, type)
            break
          case "ul":
            this.__list(current, 0, type)
            break
          case "br":
              this.__eol()
            break
          case "span":
            if (current.className === "typed-cursor") {
              break
            }
          default:
            text = (current.innerText != null) ? current.innerText : current.textContent
            if (text.trim() !==  "") {
              this.__line(current, false, 0, current.localName === "b", type)
            }
        }
        contentIndex++;
      }
      return this.configuration[type];
    },
    __eol() {
      this.configuration.comment.slideConfiguration.push({ text: "", options: { breakLine: true, bullet: false, fontSize: 7 } })
    }
  }
}
</script>